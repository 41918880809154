/** @format */

* @format button {
  width: 120px;
  height: 50px;
  color: white;
  background-color: black;
  border: none;
}
.BB {
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(0, 0, 0);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: fit-content;
  color: white;
  padding: 1rem;
  overflow: hidden;
  width: 32%;
  scale: 1;
  transition: width 2s ease;
}
.BBS {
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(0, 0, 0);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: fit-content;
  color: white;
  padding: 1rem;
  overflow: hidden;
  width: 86%;
  scale: 1;
  transition: width 2s ease;
}
.tog {
  display: none;
}
.vis {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.MB {
  width: 100%;
  height: fit-content;
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
}
.SM {
  height: 100%;
  width: 33.3%;
  margin-right: 20px;
  scroll-snap-align: start;
}

.SMB {
  height: 100%;
  width: 100%;
}
.dayB {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  width: 100%;
  text-align: center;
}
.DayBox {
  position: relative;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.DayBox.confirmed {
  background-color: lightgreen;
  color: lightgreen;
}

.DayBox.pending {
  background-color: yellow;
  color: yellow;
}

.PendingPopup {
  position: absolute;
  top: 0;
  left: 20px;
  display: none;
  background-color: rgba(0, 180, 81, 0.601);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  padding: 10px;
  z-index: 1000;
  width: 50px;
  font-size: 0.5rem;
  border-radius: 8px;
}

.DayBox.pending:hover .PendingPopup {
  display: block;
}
.PendingPop {
  position: absolute;
  top: 0;
  left: 0px;
  background-color: rgba(0, 180, 81, 0.601);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  z-index: 1000;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  color: black;
}
.PendingPop p {
  margin: none;
  font-size: 0.7rem;
}

.DayBox.pending:hover .PendingPop {
  display: block;
}

.DayBox.killed {
  background-color: salmon;
  color: salmon;
}

.DayBox.artist {
  background-color: lightblue;
  color: lightblue;
}
.EmptyDay {
  border-radius: 4px;
}
.GridCal div:not(.EmptyDay):hover {
  border: solid white 1px;
  cursor: pointer;
  padding: 1px;
  color: rgb(0, 0, 0) !important;
}
.dayB p {
  font-size: 0.5rem;
}
.Bday {
  margin: 0;
  text-align: center;
  border-right: solid 1px white;
}
.GridCal {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  width: 100%;
}
.GridCal div {
  text-align: center;
  font-size: 0.7rem;
  height: auto;
  width: auto;
  color: black;
}
.Ledigt {
  background-color: rgb(255, 255, 255);
  width: 15px;
  height: 15px;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: black;
}
.Ledigt:hover {
  cursor: pointer;
}

@media (max-width: 900px) {
  .SM {
    width: 50%;
  }
  .dayB {
    gap: 7px;
  }
}
@media (max-width: 700px) {
  .SM {
    width: 50%;
  }
  .GridCal div {
    font-size: 0.5rem;
  }
  .dayB p {
    font-size: 0.5rem;
  }
  .dayB {
    gap: 7px;
  }
  .Bday {
    font-size: 1rem;
  }
}
@media (max-width: 540px) {
  .SM {
    width: auto;
  }
  .GridCal div {
    font-size: 0.4rem;
  }
  .dayB p {
    font-size: 0.5rem;
  }
  .dayB {
    gap: 7px;
  }
  .Bday {
    font-size: 0.6rem;
  }
}
