@import url(https://fonts.googleapis.com/css2?family=Anton&family=Secular+One&family=Shrikhand&&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Secular+One&family=Shrikhand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** @format */
body {
  font-family: Arial !important;
}

h6,
.h6 {
  font-size: 1rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

/* Range Slider */
.slider-thumb {
  background: slategray;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 100%;
  line-height: 28px;
  border: 1px solid #fff;
  font-size: 12px;
}

.slider-thumb:hover {
  box-shadow: 0px 0px 15px white;
}

.example-track {
  background: #0d6efd55;
  height: 2px;
  border-radius: 15px;
  margin-top: 14px;
}

.example-track-1 {
  background: #0d6efd55;
  height: 10px;
  border-radius: 15px;
  margin-top: 10px;
}

/* SideBar */

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  max-width: 250px;
  min-width: 80px;
  width: 27vw;
}

.sidebaritem {
  padding: 10px 0px;
  border-bottom: 1px solid #efefef;
}

.sidebaritem:hover {
  /* background-color: #efefef; */
}

/* Preffered Days */

.form-switch .form-check-input {
  width: 25px;
  margin-left: -2.5em;
  background-image: url(data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg viewBox=-4 -4 8 8%3e%3ccircle r=3 fill=rgba%280, 0 0 0.25%29/%3e%3c/svg%3e);
  background-position: center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

.form-switch {
  padding-left: 1.7em;
  width: 25px;
  float: left;
}

#preferreddays label {
  /* font-size: 10px;
  margin-top: 22px;
  margin-left: -35px; */
}

div#preferreddays {
  /* margin-left: 15px; */
}

/* 

.mittcontent{
  float:right;
  width:60%;
  overflow-y: scroll;
} */

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
  border: none !important;
}

.rbc-day-bg {
  flex: 1 0;
  background: #ffffff11;
  margin: 4px;
  border: none !important;
}

a {
  text-decoration: none !important;
  color: white !important;
}

/* CALENDAR */

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: center;
  /* background: #444; */
  margin: 4px;
  border-radius: 5px 5px 0px 0px;
  font-size: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 5px 5px !important;
  /* background-color: #3174ad; */
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 90% !important;
  text-align: left;
  margin-left: 5px !important;
  font-size: 14px !important;
  opacity: 0.8;
  transition: all 0.5s ease-out !important;
}

.rbc-event {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.rbc-event:hover {
  opacity: 1;
  transition: all 0.1s ease-out !important;
}

.rbc-addons-dnd-is-dragging .rbc-event {
  max-width: 90% !important;
  transition: all 0.5s ease-out !important;
}

.rbc-calendar * {
  border: none !important;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
  background: #000000dd;
  width: 100%;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
  background: #000000dd;
  border-left: 1px solid #444 !important;
  width: 100%;
}

.rbc-off-range-bg {
  background: none !important;
}

/* CALENDAR ------- END */

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  display: none !important;
}

h6 {
  font-size: 14px;
}

stats {
  font-size: 14px;
  color: #ffffff55;
}

dontshow {
  display: none;
}

/*   EVENTCARDS   */

.statusDot {
  float: right;
  border: 1px solid #ffffff30;
  border-radius: 50px;
  box-shadow: 1px 1px 1px black;
}

@-webkit-keyframes monthData-slideIn {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

@keyframes monthData-slideIn {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}

#monthDataStatic {
  position: absolute;
  padding: 15px;
  width: 100%;
  height: 50px;
  left: 35px;
  top: 0px;
  z-index: 1501;
  background: #111;
  border-bottom: 1px solid #444;
  margin-bottom: 10px;
}

.badgeGroup {
  /* background: rgba(255, 255, 0, 0.075); */
  padding: 0px 0px;
}

.badge {
  margin: 0px 10px;
}

.eventContainer {
  position: relative;
  scroll-snap-type: y mandatory;
  padding: 10px;
  width: 100% !important;
  height: 70vh !important;
  overflow-y: scroll;
  /* box-shadow: 15px 14px 14px 10px #000 inset; */
}

.eventYear {
  float: left;
  width: 100%;
}

.eventMonth {
  margin-top: 100px !important;
  margin-bottom: 200vh;
  height: 70vh;
  /* padding-top: 20px; */
  width: 100%;
  position: relative;
  /* padding-left: 30px; */
  justify-content: center;
  align-items: center;
  overflow: scroll;
  scroll-snap-align: start;
  /* background-color: #f0dcca; */
  /* max-height: 80vh; */
}

.eventMonthStats {
  border-bottom: 1px solid #444;
  position: relative;
  height: 40px;
  padding-top: 15px;
  padding-bottom: 35px;
  width: 100%;
  padding-left: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #111 !important;
  z-index: 1502;
}

.miniPie {
  width: 50px;
  display: inline-block;
  position: absolute;
  height: 30px;
  margin-top: -12px;
}

.eventData {
  /* height: 70vh !important; */
  overflow: scroll;
  margin-top: 10px;
}

.eventCard {
  position: relative;
  display: block;
  height: 300px !important;
  background: #222222;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
  height: 250px;
  overflow: none;
}

.DayOfTheWeekMini {
  position: relative;
  color: #777;
  float: left;
  font-size: 10px;
  padding: 2px;
  top: 0;
  /* height: 18px; */
  line-height: 12px;
}

.TheActualDayOfTheWeek {
  /* border-bottom: 1px solid #aaa; */
  /* background: #777; */
  color: white;
  margin-top: 0px;
  /* padding: 4px; */
  /* line-height: 8px; */
  /* width: 10px; */
  /* height: 10px; */
  /* border-radius: 10px; */
}

.badgeGroup {
  float: right;
  margin-right: 40px;
}

.artistImage {
  max-height: 200px;
  max-width: 200px;
  overflow: hidden;
  border-radius: 10px;
  float: right;
  margin-top: 5px;
  /* border: 1px solid #639870; */
}

.artistImageFlat {
  margin-top: 5px;
}

.artistImageB img {
  width: 200px;
  height: auto;
}

.dateChip {
  position: relative;
}

.dayOfTheWeek {
  position: absolute;
  color: #aaa;
  font-size: 10px;
  left: 10px;
  top: 5px;
}

.bookingStatus {
  position: absolute;
  font-size: 10px;
  left: 10px;
  top: 40px;
}

@media only screen and (max-width: 600px) {
  .eventCard {
    display: block;
    height: 160px !important;
  }

  .artistImage {
    max-height: 140px;
    max-width: 140px;
  }
}

.eventCard:hover {
  color: rgb(194, 160, 166);
  background: #212121 !important;
}

.eventNav {
  position: relative;
  padding: 10px;
  width: 100%;
  top: 0px;
  display: none !important;
}

.eventDisplayBtn {
}

.monthNav {
  display: inline !important;
  margin-left: 10px;
}

/*  SIDEBAR */

/* .sidebar itemtitle {
  color: white;
} */

.selectVenue {
  background-color: transparent !important;
}

.selectVenue.Mui-disabled {
  color: transparent !important; /* Ensure it stays transparent when disabled */
}

#outlet {
  height: 90vh;
  /* background-color: rgba(0, 128, 0, 0.081); */
}

/*  MonthRangePicker */

.monthsToPick {
  display: inline-flex;
  padding: 2px 6px;
  font-size: 12px;
  height: 24px;
  width: 32px;
  /* transition: all 0.1s; */
}

.monthsToPick:hover {
  background: #00ffd499;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 18px;
  text-align: center;
  color: black;
}

.monthPickerModule {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 15px;
  z-index: 450;
  background: linear-gradient(115deg, #222222aa, #22222299);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 20px 20px;
  box-shadow: 10px 10px 10px black;
}

.monthPicker {
  padding: 20px;
  width: 520px;
  height: 175px;
  overflow: scroll;
  -webkit-animation: monthPickerGrow 50ms ease-in-out;
          animation: monthPickerGrow 50ms ease-in-out;
}

.monthPicker .year {
  width: 500px !important;
}

.ShownMonthYear {
  cursor: pointer;

  width: 130px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.ShownMonthYear:hover {
  color: #00ffd499;
}

@-webkit-keyframes monthPickerGrow {
  from {
    height: 0px;
  }

  to {
    height: 175px;
  }
}

@keyframes monthPickerGrow {
  from {
    height: 0px;
  }

  to {
    height: 175px;
  }
}

.year {
  float: left;
}

.yearsColumn {
  width: 20%;
  float: left;
}

.months {
  width: 80%;
  float: right;
}

/* SEND AVAILS */

.availItem {
  background: #444;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  padding: 0px 5px;
  margin: 2px;
  /* float: right; */
  color: white;
  width: auto;
  display: inline-block;
  z-index: 2;
}

.awaitingItem {
  background: #444;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  padding: 0px 5px;
  margin: 2px;
  /* float: right; */
  color: orange;
  width: auto;
  display: inline-block;
}

/* stats.dataPanel {
  position: absolute;
  left: 300px;
  background: #333;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  z-index: 1;
  box-shadow: 5px 5px 5px black;
  max-height: 30vh;
  overflow: scroll;
  border: 1px solid;
}

stats.dataPanel:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: #00aabb;
  border-left: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-left: -20px;
} */

#data {
  position: absolute;
  left: 300px;
  top: 50px;
  z-index: 2;
}

stats.dataPanel {
  position: relative;
  display: table;
  background: #121212f5;
  border-radius: 0.4em;
  border: 4px solid;
  /* left: 300px; */
  /* background: #333; */
  padding: 20px;
  /* border-radius: 10px; */
  width: 600px;
  max-height: 30vh;
  overflow: scroll;
  box-shadow: 5px 5px 5px black;
  height: 30vh;
  overflow-y: scroll;
}

stats.dataPanel:after {
  content: "";
  position: absolute;
  left: -4px;
  top: 50%;
  width: 20px;
  height: 0;
  border: 1px solid;
  /* border-right-color: #fff; */
  border-left: 0;
  border-bottom: 1px solid;
  margin-top: -10px;
  margin-left: -20px;
}

.tbg {
  /* width: 70vw;
  height: auto; */
  flex-wrap: wrap;
}

.tbg.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  margin-left: 4px;
  border-left: 1px solid transparent;
  /* border-top-left-radius: 0;
  border-bottom-left-radius: 0; */
}

.tbg.css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* background: #509a6caa; */
  margin: 4px !important;
  padding: 50px 5px;
  border: 1px solid #555 !important;
}

.formSectionLabel,
.formSectionLabel .MuiFormControlLabel-label {
  margin-top: 30px !important;
  margin-bottom: 0px !important;
  width: 70vw !important;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
}

formsectionheader {
  margin-top: 0px !important;
  margin-bottom: 10px;
  padding: 5px 0px;
  display: block;
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
  color: #777;
  border-bottom: 1px solid #333;
  font-size: 14px;
}

/* OWNER  */

.venueCard {
  background: #111 !important;
  justify-content: space-between !important;
}

.venueCard:hover {
  background: #222 !important;
}

.icon:hover {
  opacity: 0.8;
  color: pink;
}

/* editor chip*/

.editorChip {
  float: left;
  width: 100%;
  top: 0px;
  border: 1px solid;
  border-radius: 20px;
  padding: 10px;
}

editorName {
  margin-left: 15px;
  font-size: 20px;
  font-weight: 900;
}

bild {
  background: #777;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 50px;
  line-height: 40px;
}

fieldset {
  border: none !important;
  background: currentColor;
  opacity: 0.1;
  border-radius: 10px !important;
}

/* MuiOutlinedInput,
.css-myb2s4-MuiInputBase-input-MuiOutlinedInput-input::focus {
  border: 2px dashed yellow;
} */

/* .eventCard.flatEvent.artistImage {
  background: orange;
  width: 150px;
  height: 150px; 
}*/

.artistImageB {
  max-height: 180px;
  max-width: 180px;
  overflow: hidden;
  border-radius: 10px;
  float: left !important;
  margin-right: 20px !important;
  background: #44444499;
  margin-top: 5px;
}

/*--------HOME PAGE-------*/

.pageContainer {
  position: relative;
  scroll-snap-type: y mandatory;
  width: 100% !important;
  height: 100vh !important;
  overflow-y: scroll;
}

.fullPage {
  scroll-snap-align: start;
  height: 100vh;
}

.blurpage {
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_v1.svg?alt=media&token=3c591f10-0cf3-4175-99ed-35a6c326ca46); */
  /* -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
  z-index: -3; */
}

.footer {
  scroll-snap-align: start;
}

#BillbackVideo {
  -webkit-filter: blur(10px);
          filter: blur(10px);
  transition: all, 1s;
  opacity: 1;
  z-index: -1;
  /* zoom: 0.9; */
  min-width: 100%;
  min-height: 50vh;
  /* mask-size: contain; */

  /* -webkit-mask-repeat: no-repeat; */
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-name: billback;
          animation-name: billback;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  /* /* animation-direction: alternate; */
  opacity: 1;
}

@-webkit-keyframes billback {
  10% {
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4); */
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
    /* -webkit-mask-repeat: no-repeat; */
    /* margin-left: 100%; */
    opacity: 1;
    /*width: 300%; */
  }

  30% {
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4); */
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
    /* -webkit-mask-repeat: no-repeat; */
    /* margin-left: 100%; */
    opacity: 0.1;
    /*width: 300%; */
  }

  50% {
    /* -webkit-mask-repeat: no-repeat; */
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3); */
    opacity: 0.1;
    /* margin-left: 0%; */
    /* width: 100%; */
  }

  90% {
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3); */
    opacity: 1;
    /* margin-left: 0%; */
    /* width: 100%; */
  }
}

@keyframes billback {
  10% {
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4); */
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
    /* -webkit-mask-repeat: no-repeat; */
    /* margin-left: 100%; */
    opacity: 1;
    /*width: 300%; */
  }

  30% {
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4); */
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
    /* -webkit-mask-repeat: no-repeat; */
    /* margin-left: 100%; */
    opacity: 0.1;
    /*width: 300%; */
  }

  50% {
    /* -webkit-mask-repeat: no-repeat; */
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3); */
    opacity: 0.1;
    /* margin-left: 0%; */
    /* width: 100%; */
  }

  90% {
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
    /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3); */
    opacity: 1;
    /* margin-left: 0%; */
    /* width: 100%; */
  }
}

/* @import url("https://fonts.googleapis.com/css2?family=Anton&family=Secular+One&family=Shrikhand&display=swap"); */

superHeader {
  font-family: "Anton", sans-serif;
  /* font-family: "Questrial", sans-serif; */
  font-size: 7vw;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: 38vw;
  line-height: 7vw;
  position: relative;
  margin-top: 45vh;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-shadow: 0px 0px 55px rgb(0 57 20 / 50%);
}

centerOfPage {
  display: block;
  position: relative;
  text-shadow: 0px 0px 55px rgb(0 57 20 / 50%);
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  superHeader {
    width: 70%;
    font-family: "Anton", sans-serif;
    font-size: 11vw;
    text-transform: uppercase;
    text-align: center;
    display: block;
    line-height: 14vw;
    position: relative;
    margin-top: 45vh;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

.fixedContainer {
  padding: 10px;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #00000099;
}

/* input::before,
input::after,
input {
  border: none !important;
}
.css-18ydcaa-MuiInputBase-root-MuiOutlinedInput-root {
  border: 20px !important;
} */

h1 {
  font-family: "Avenir Black", sans-serif;
  /* text-transform: uppercase; */
  font-weight: 900 !important;
  font-size: 40px;
}

h2 {
  font-family: "Avenir Black", sans-serif;
  /* text-transform: uppercase; */
  font-weight: 900 !important;
  font-size: 40px;
}

h4 {
  font-size: 1.5rem;
  /* font-family: avenir black; */
  font-family: av "Avenir Black", sans-serif;
  font-weight: 900 !important;
}

h6 {
  font-family: "Avenir Black", sans-serif;
  /* text-transform: uppercase; */
  font-weight: 400;
  font-size: 1rem;
}

p {
  font-family: "Avenir Black", sans-serif;
  /* text-transform: uppercase; */
  font-weight: 900;
  font-size: 14px;
}

body {
  font-weight: 400;
  font-size: 14px;
}

.imageCrop {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.imageUpload {
  position: relative;
  width: 100%;
  height: 400px;
  display: block;
  margin-bottom: 60px;
}

.venueDescriptionTextField {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  border: 2px solid #333;
  margin-bottom: 60px;
}

.imageSlider {
  position: relative;
  width: 70%;
  margin-left: 35px;
}

.imageDoneBtn {
  margin-left: 35px;
  position: relative;
}

.imageCropControlers {
  position: relative;
  top: 310px;
  width: 100%;
  display: inline-flex;
}

.menuOption {
  font-size: 14px;
}

/*----Theme-----*/

.themeToggle {
  background: none !important;
  border: none !important;
}

/*----Booking Form----*/

.formArtistImage {
  z-index: 10000;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"), url(/static/media/AvenirBlack.d10ab385.ttf);
}

.logosvg {
  /* background-color: #ffd800; */
  /* background-color: #00ffd4; */
  /* background-color: #ffffff;
  position: absolute;
  top: 8px;
  left: 50px;
  width: 400px;
  height: 19px;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat; */
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_v1.svg?alt=media&token=3c591f10-0cf3-4175-99ed-35a6c326ca46);
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_v2.svg?alt=media&token=acbff01f-6d58-412b-bd37-7bc29b6f6397);
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_v3.svg?alt=media&token=e1445606-6655-40e1-87f5-6b8ddfcc4c97); */
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_v4.svg?alt=media&token=54e83636-1bea-4f28-97bb-456f2a0b23ac); */

  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);

  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_black.svg?alt=media&token=c45d7340-c292-46ac-97ca-f4a0b6630a3c);
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_black.svg?alt=media&token=c45d7340-c292-46ac-97ca-f4a0b6630a3c); */

  /* mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_black.svg?alt=media&token=c45d7340-c292-46ac-97ca-f4a0b6630a3c); */
}

.nuLogoSvg {
  background-color: #fff;
  position: absolute;
  top: 14px;
  left: 50px;
  width: 130px;
  height: 25px;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_final.svg?alt=media&token=afb33ecb-24d6-4158-8206-5254c01618a3);
  -webkit-animation: fadeOutInABit 6000ms;
          animation: fadeOutInABit 6000ms;
  opacity: 0;
  transition: all 300;
}

@-webkit-keyframes fadeOutInABit {
  0% {
    left: 45px;
    /* width: 30px; */
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 50px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeOutInABit {
  0% {
    left: 45px;
    /* width: 30px; */
    opacity: 0;
  }
  20% {
    opacity: 1;
    left: 50px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.circlesvg {
  opacity: 0.5;
  background-color: #00ffd4;
  position: absolute;
  top: 15px;
  left: 20px;
  width: 25px;
  height: 25px;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvh_v1.svg?alt=media&token=09053721-a760-43be-92fd-fdb6d87b0bae); */
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvh_v2.svg?alt=media&token=beb22f66-3485-448a-882b-96ac8c88bb46); */
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvh_v1.svg?alt=media&token=22f0207d-5368-420f-83f0-976bc0739e64); */

  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvh_final.svg?alt=media&token=78889ea0-37fe-45dd-8758-170306c1901e); */
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvh_v3.svg?alt=media&token=1f51feb9-19a1-4aaf-97e0-8cb75bc45a29); */
  /* -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvh_v4.svg?alt=media&token=35a1c9e5-0f6a-4b0b-9af3-661e03a9ec83); */

  /* mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_black.svg?alt=media&token=c45d7340-c292-46ac-97ca-f4a0b6630a3c); */
  -webkit-animation: imgFadeOutInABit 7000ms;
          animation: imgFadeOutInABit 7000ms;
}

@-webkit-keyframes imgFadeOutInABit {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes imgFadeOutInABit {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* .logobkg {
  background-color: #ffffff99;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2Fvenuehub_v1.svg?alt=media&token=3c591f10-0cf3-4175-99ed-35a6c326ca46);
} */

.quotes {
  /* -webkit-backdrop-filter: blur(17px); */
  /* backdrop-filter: blur(17px); */
  padding: 20px;
  /* background: #00ffd411; */
  color: white;
  font-weight: 600;
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  margin-top: 20vh;
  border-radius: 5px;
  /* border-top: 5px solid #ffffff99;
  border-right: 5px solid #ffffff55;
  border-left: 5px solid #ffffff11;
  border-bottom: 5px solid #ffffff33; */
  /* box-shadow: 0px 0px 100px #fff; */
}

@media only screen and (max-width: 600px) {
  .quotes {
    width: 99vw;
    font-size: 18px;
    margin-top: 10vh;
  }
}

.logoCenter {
  /* background-color: #00ffd4; */
  background-color: #00373c;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: 250px;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  z-index: 10;

  -webkit-mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);

  mask-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FResurs%201.svg?alt=media&token=517af342-186c-4317-9204-47580e024ce4);
  -webkit-filter: drop-shadow(0 0 0.75rem rgb(0, 0, 0));
          filter: drop-shadow(0 0 0.75rem rgb(0, 0, 0));
}

/*Pie chart own*/

.newPie svg {
  /* transform: rotate(-90deg); */
  /* background: yellowgreen; */
  /* border-radius: 50%;*/
}

circle {
  /* fill: #555; */
  /* r: 5px;
  cx: 10px;
  cy: 10px;
  fill: transparent;
  stroke-width: 5; */
  /* stroke: rgb(73, 249, 255); */
  /* transform: rotate(-90deg) translate(-40px, -10px); */
  /* stroke-dasharray: calc(50 * 62.8319 / 100), 62.8319; */
  /* animation: pieFill 2s linear infinite;
  animation-iteration-count: 1; */
}

.miniMonth .day {
  cursor: pointer;

  background: #33333355;
  transition: all 0.5s;
}

.miniMonth .day:hover {
  background: #00ffd433;
  transition: all 0s;
}

/* .back-map {
  filter: blur(10px);
  transition: all 2s;
} */

.GreenVenue {
  position: absolute;
  right: 24px;
  font-size: 22px;
  top: -196px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  overflow: hidden;
  text-indent: 3px;
  -webkit-filter: drop-shadow(-1px -1px 4px #fff);
          filter: drop-shadow(-1px -1px 4px #fff);
  box-shadow: 1px 17px 17px #fff inset;
  border: 3px solid #016d3b;
}

.GreenLine {
  border: 5px solid white;
  position: absolute;
  width: 350px;
  left: -4px;
  top: -16px;
  display: none;
}

/* .LikeButton {
  position: absolute;
  right: 30px;
  font-size: 20px;
  filter: saturate(0);
  bottom: 20px;
  color: #ffffff;
  height: auto;
  cursor: grab;
} */

/* .LikeButton svg {
  filter: drop-shadow(2px 4px 6px white);
} */

/* .VenueCardBooking {
  float: left;
  margin-bottom: 10px;
  border-radius: 0px;
  margin-right: 10px;
  border: 1px solid white;
  height: 87px !important;
  width: 45%;
  min-width: 370px;
  color: black;
  position: relative;
  background: #ffffff;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid white;
} */

/* .venueCardBookingImage {
  height: 88px;
  width: 160px;
  float: left;
  overflow: hidden;
  margin-right: 10px;
}

.VenueCardBooking img {
  width: 140px;
  height: auto;
  filter: saturate(0);
  float: left;
}

.VenueCardBooking:hover img {
  filter: saturate(1);
} 



.venueImgOverlay {
  background: #00a0af;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 140px;
  height: 88px;
  mix-blend-mode: overlay;
}

.venueImgOverlay:hover {
  display: none;
}

.vcVenue p {
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  top: 5px;
  left: 150px;
  width: calc((100%)-205px);
  overflow: hidden;
  height: 40px;
  text-overflow: ellipsis " [..]";
}

.vcInfo {
  position: absolute;
  bottom: 5px;
  font-size: 10px;
  left: 140px;
}

.vcInfo svg {
  margin-left: 10px;
  margin-right: 2px;
  width: 15px;
  height: 15px;
  color: #00000077;
}

.vcLikeBtn {
  left: 0px;
  font-size: 20px;
  color: #ffffff;
}

.vcLikeBtn svg {
  position: absolute;
  top: 60px;
  right: 5px;
  color: #ffffff;
  background: transparent;
  filter: drop-shadow(5px 5px 5px #777777);
}

.vcRating {
  font-size: 15px;
  position: absolute;
  left: 5px;
  z-index: 2;
}

.vcRating {
  filter: drop-shadow(2px 2px 2px);
}

.vcBadge {
  position: absolute !important;
  top: 40px;
  right: 20px;
  z-index: 1;
}


*/

.VenueCardBooking {
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  height: 250px !important;
  width: 20%;
  min-width: 250px;
  position: relative;
  background: linear-gradient(#444, #222) !important;
  overflow: hidden;
  border-radius: 20px !important;
  padding: 15px;
}

.venueCardBookingImage {
  height: 120px;
  width: 100% !important;
  float: left;
  overflow: hidden;
  /* margin-right: 10px; */
}

.VenueCardBooking img {
  width: 100%;
  height: auto;
  /* filter: saturate(0); */
  /* float: left; */
}

.VenueCardBooking:hover img {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}

.venueImgOverlay {
  background: #00a0af;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 140px;
  height: 88px;
  mix-blend-mode: overlay;
}

.venueImgOverlay:hover {
  display: none;
}

.vcVenue {
  width: 80%;
  text-overflow: ellipsis;
}

.vcVenue p {
  white-space: nowrap;
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  top: 143px;
  left: 15px;
  overflow: hidden;
  height: 20px;
  text-overflow: ellipsis;
  width: 90%;
}

.vcInfo {
  position: absolute;
  bottom: 70px;
  font-size: 10px;
  left: 0px;
  opacity: 0.5;
}

.vcInfo svg {
  margin-left: 10px;
  margin-right: 2px;
  width: 15px;
  height: 15px;
}

.vcLikeBtn {
  display: none;
  left: 0px;
  font-size: 20px;
  color: #ffffff;
}

.vcLikeBtn svg {
  position: absolute;
  bottom: 6px;
  right: 15px;
  color: #ffffff;
  -webkit-filter: drop-shadow(2px 2px 5px #777777);
          filter: drop-shadow(2px 2px 5px #777777);
}

.vcRating {
  font-size: 15px;
  position: absolute;
  left: 5px;
  z-index: 2;
}

.vcRating {
  -webkit-filter: drop-shadow(2px 2px 2px);
          filter: drop-shadow(2px 2px 2px);
}

.vcBadge {
  position: absolute !important;
  top: 15px;
  right: 20px;
  z-index: 1;
  -webkit-filter: drop-shadow(3px 3px 5px black);
          filter: drop-shadow(3px 3px 5px black);
}

.vcProductSelect {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.timelineModal {
  position: fixed;
  bottom: 5%;
  top: 38px;
  right: 0px;
  padding: 20px;
  width: 30%;
  background: #11111166;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* border: 2px solid #000; */
  /* boxShadow: 24,
  p: 4, */
  transition: all 1s;
  overflow: scroll;
  -webkit-animation: timeline 3000ms;
          animation: timeline 3000ms;
}

@-webkit-keyframes timeline {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    margin-right: -500px;
    width: 0px !important;
    /* filter: blur(10px); */
  }
  30% {
    margin-right: 00px;
    height: 100%;
  }

  100% {
    height: 100%;
  }
}

@keyframes timeline {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    margin-right: -500px;
    width: 0px !important;
    /* filter: blur(10px); */
  }
  30% {
    margin-right: 00px;
    height: 100%;
  }

  100% {
    height: 100%;
  }
}

.tl {
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
  background: #11111166;
}

.tlCloseBtn {
  position: fixed !important;
  top: 20px;
  right: 20px;
}

.tl .VenueCardBooking {
  float: left;
  margin-bottom: 20px;
  border-radius: 20px;
  margin-right: 10px;
  border: 1px solid white;
  height: 90px;
  width: 100%;
  background: #ffffff99;
  /* border-radius: 5px; */
  /* overflow: hidden; */
  color: black;
}

.tlDate {
  /* height: 100%; */
  background: orange;
  color: black;
  padding: 2px 10px;
  width: 110px;
  text-align: center;
  border-radius: 1%;
  float: left;
  margin-right: 15px;
  position: relative;
  z-index: 2;
  float: left;
  margin-bottom: -45px;
  border-radius: 20px 0 0 0;
  border-top: 1px solid white;
  border-left: 1px solid white;
}

.span.tlCity {
  font-style: italic;
  opacity: 0.5;
}

.tlVenue {
  /* border: 1px solid #aaa; */
  padding: 0px px 6px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: ;
}

.tlContent {
  /* border: 3px solid white; */
  /* border-radius: 50px; */
  min-width: 50%;
  /* float: left; */
}

.tl .vcRating {
  display: none;
}

.tl .vcLikeBtn {
  display: none;
}

.TimelineItem {
  margin-bottom: 10px;
  background: #ffffff33;
}

.timeLineLine {
  height: 52px;
  position: relative;
  left: 50%;
  border-left: 4px solid orange;
  top: 87px;
}

.tourCalc svg {
  zoom: 0.8;
  color: orange;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #11111166;
  padding: 3px;
}

.tc {
  float: left;
  height: 30px;
  margin: 0px 10px;
  border-radius: 20px;
  padding: 2px 10px;
  box-shadow: 0px 0px 0px black;
}

.tcIcon {
  float: left;
}

.tourCalc {
  background: #ffffff22;
  padding: 20px;
  width: 100%;
  box-shadow: 4px 4px 5px #00000022;
  border-radius: 10px;
  margin-bottom: 15px;
  height: 200px;
}

.tc span {
  padding: 0px;
  line-height: 20px;
  font-size: "10px" !important;
  margin-left: 10px;
}

.tc .tcCo2e {
  border-radius: 20px;
  padding: 4px 10px;
  box-shadow: 2px 2px 2px black;
  margin-left: -10px;
  background: #00373c99;
}
.tc .tcCo2e svg {
  color: green;
  background: rgb(255, 255, 255, 0.8);
}

.tourSwitch {
  /* position: absolute;
  bottom: 90px;
  left: 25px; */
  float: left;
}

.mapSwitch {
  /* position: absolute;
  bottom: 50px;
  left: 25px; */
  margin-left: 20px;
  float: left;
}

.mapBadge {
  position: absolute;
  top: -25px;
  right: 15px;
}

.mapVenue {
  position: absolute;
  top: -36px;
  /* top: 0px; */
  /* left: -100px; */
  left: 0px;
  width: auto;
  left: 0px;
  padding: 0px 5px;
  text-align: center;
  border: 1px solid white;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: #11111166;
  border-radius: 1rem;
}
.mapTooltip {
  background: #11111166;
  width: 500px;
}

.mapTooltip .vcBadge {
  display: none;
}

.mapDate {
  background: rgb(255, 255, 255);
  color: black;
  padding: 0px 5px;
  position: relative;
  /* margin-top: -20px; */
  width: 120px;
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid #aaa;
  border-radius: 5px;
  top: -25px;
}

.mapDate .picked {
  background: "green";
  display: none !important;
}

.mapDate .blobAwaiting,
.mapDate .blobPending,
.mapDate .blobConfirmed {
  position: relative;
  left: 3px;
  top: -2px;
}

.blobLine {
  border-left: 1px solid green;
  height: 14px;
  margin-left: -16px;
  /* position: absolute; */
  height: 21px;
  float: left;
}

.tlVenue {
  /* border: 1px solid #aaa; */
  padding: 0px px 6px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: ;
}

/* .mapTooltip .tlContent {
  min-width: 50%;
} */

.mapTooltip .vcRating {
}

.mapTooltip .vcLikeBtn {
}

.mapTooltip .VenueCardBooking {
  float: left;
  margin-bottom: 20px;
  border-radius: 0px;
  margin-right: 10px;
  /* border: 1px solid white; */
  height: 90px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  color: black;
  box-shadow: 5px 5px 10px black;
}

.mapTooltip .venueImgOverlay {
  display: none;
}

.mapTooltip img {
  -webkit-filter: saturate(1);
          filter: saturate(1);
}

.availsPicker {
  position: absolute;
  top: 120px;
  width: 500px;
  height: 100px;
  overflow: scroll;
}

.availPick {
  /* color: #00ffd4; */
  color: white;
  padding: 0px 2px;
  font-size: 12px;
  /* border-radius: 5px; */
  margin-left: 2px;
  display: inline-flex;
  width: 100px;
  background: black;
  margin-bottom: 2px;
  -webkit-animation: appear 1000ms ease-in-out;
          animation: appear 1000ms ease-in-out;
}

/* .MuiDataGrid-row {
  animation: appear 1000ms ease-in-out;
} */

.availPick:hover {
  opacity: 0.5;
}

.availPick .goo {
  padding: 0px 2px;
  color: #bbb;
  margin-right: 2px;
}

.removeProposed {
  position: absolute;
  z-index: 500;
  top: -60px;
  left: 400px;
  color: orange !important;
  border: 1px solid orange;
}

.removeProposed svg {
  color: orange;
  -webkit-filter: drop-shadow(2px 2px 2px black);
          filter: drop-shadow(2px 2px 2px black);
  transition: all 1s;
}

.removeProposed:hover svg {
  color: orange;
  -webkit-filter: drop-shadow(0px 0px 0px black);
          filter: drop-shadow(0px 0px 0px black);
  transition: all 200;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    margin-top: -50px;
    /* width: 0px !important; */
    /* filter: blur(10px); */
  }
  20% {
    opacity: 0.5;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    margin-top: 0px;
  }
  opacity: 1;
  25% {
  }
  55% {
    opacity: 0.5;
    /* width: 100%; */
    height: 90px;
  }
  99% {
  }
  100% {
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    margin-top: -50px;
    /* width: 0px !important; */
    /* filter: blur(10px); */
  }
  20% {
    opacity: 0.5;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    margin-top: 0px;
  }
  opacity: 1;
  25% {
  }
  55% {
    opacity: 0.5;
    /* width: 100%; */
    height: 90px;
  }
  99% {
  }
  100% {
  }
}

.VenueCardBooking {
  -webkit-animation: appear 1000ms ease-in-out;
          animation: appear 1000ms ease-in-out;
  height: 0px;
  transition: all 300;
}

.availsPicker {
  -webkit-animation: appear 1000ms ease-in-out;
          animation: appear 1000ms ease-in-out;
  transition: all 300;
}

@-webkit-keyframes imgAppear {
  0% {
    opacity: 0;
    /* overflow: hidden; */
    /* margin-top: -20px; */
    /* width: 0px; */
    /* filter: blur(0px); */
  }
  20% {
    /* opacity: 0.5; */
    /* margin-top: 0px; */
    /* width: 100%; */
  }
  opacity: 1;
  25% {
  }
  35% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  99% {
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes imgAppear {
  0% {
    opacity: 0;
    /* overflow: hidden; */
    /* margin-top: -20px; */
    /* width: 0px; */
    /* filter: blur(0px); */
  }
  20% {
    /* opacity: 0.5; */
    /* margin-top: 0px; */
    /* width: 100%; */
  }
  opacity: 1;
  25% {
  }
  35% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  99% {
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

img {
  -webkit-animation: imgAppear 300ms ease-in-out;
          animation: imgAppear 300ms ease-in-out;
}

p {
  -webkit-animation: imgAppear 600ms ease-in-out;
          animation: imgAppear 600ms ease-in-out;
}

.blobConfirmed {
  background: green;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-left: 0px;
  margin-right: 10px;
  float: left;
}
.blobPending {
  background: orange;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-left: 0px;
  margin-right: 10px;
  float: left;
}
.blobAwaiting {
  background: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-left: 0px;
  margin-right: 10px;
  float: left;
}

.RangeSliderValues {
  font-size: 12px;
  margin-top: -20px;
  text-align: center;
}

.MuiSlider-markLabel {
  font-size: 10px !important;
  margin-top: -10px;
  opacity: 0.5;
}

.dateRange {
  font-size: "10px" !important;
}

.calendarTrigger {
  /* margin-top: 15px; */
  border: 1px solid #00ffd433;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
}

.calendarTrigger:hover {
  border: 1px solid #00ffd4;
  background: #00ffd422;
  transition: all 0.3s;
}

.filterLabel {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 100;
  opacity: 0.7;
}

.bookingCard {
  /* min-width: 140px;
  width: 100%; */
  /* min-width: 150px; */
  overflow: hidden;
  margin: 10px;
  border-radius: 20px;
  position: relative;
  padding: 15px;
  background: linear-gradient(#333, #33333344);
}

.bookingCard .titleOfEvent {
  font-family: Avenir;
  position: absolute;
  top: 155px;
  font-weight: 900;
  font-size: 15px;
  line-height: 14px;
  left: 0px;
}

.bookingCard .statusOfEvent {
  position: absolute;
  font-weight: 100;
  font-size: 12px;
  opacity: 0.5;
  font-weight: "100";
  height: 10px;
  bottom: 0px;
  right: 0px;
}

.bookingCard .dateOfEvent {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  font-weight: 900;
  font-size: 11px;
  text-indent: 5px;
  font-family: Avenir;
  padding-right: 5px;
  background: #000;
  color: #fff;
}

.bookingCard .imgOverlay {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: radial-gradient(circle, rgba(2, 0, 36, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
  border: 0px;
  mix-blend-mode: multiply;
}

.artistInfoModal .date {
  font-family: "Avenir";
  font-weight: 900;
  margin-bottom: 20px;
  font-size: 20px;
}

.editBookingIcon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  opacity: 0.5;
  zoom: 1;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid #ffffff55;
  box-shadow: 5px 5px 15px #00000099;
}

/* Inserted code */
/* @import url("https://fonts.googleapis.com/css2?family=Anton&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Secular+One&family=Shrikhand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Secular+One&family=Shrikhand&display=swap");
*/
#agreement,
#settlement {
  font-family: "Roboto Condensed", sans-serif !important;
}

/* #settlement table tr td:nth-child(5) {
  text-align: left;
} */

#settlement table {
  border-collapse: collapse;
}

#settlement table tr td th {
  text-align: left;
}

.modal-fixed-footer {
  position: fixed;
  bottom: 20px;
}

.agreementIcon {
  border-radius: 5px;
  transition: all 1s;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.agreementIcon:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: all 0.4s;
  /* background: linear-gradient(#ffffff44, #ffffff00); */
}

.agreementAction {
  /* transition: all; */
  -webkit-animation: agreementAct 4s infinite;
          animation: agreementAct 4s infinite;
}

@-webkit-keyframes agreementAct {
  0% {
    opacity: 0;
    -webkit-transform: rotateZ(-180deg);
            transform: rotateZ(-180deg);
    /* border: 0px dashed; */
    /* border-width: 10px; */
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
    /* border: 1px dashed; */
    /* border-width: 0px; */
  }
  100% {
    /* opacity: 1;
    transform: rotateZ(0deg);
    border: 1px dashed;
    border-width: 0px; */
  }
}

@keyframes agreementAct {
  0% {
    opacity: 0;
    -webkit-transform: rotateZ(-180deg);
            transform: rotateZ(-180deg);
    /* border: 0px dashed; */
    /* border-width: 10px; */
  }
  70% {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
    /* border: 1px dashed; */
    /* border-width: 0px; */
  }
  100% {
    /* opacity: 1;
    transform: rotateZ(0deg);
    border: 1px dashed;
    border-width: 0px; */
  }
}

.deleteProduct {
  float: right !important;
  font-size: 20px;
  margin-right: 10px;
}

.deleteProduct:hover {
  float: right;
  opacity: 0.5;
}

.editProduct {
  float: left !important;
  margin-right: 10px;
}

.editProduct:hover {
  float: left;
  opacity: 0.5;
}

.eventType .MuiToggleButton-root .Mui-selected {
  /* background-color: greenyellow; */
  color: #fff;
  background: linear-gradient(1deg, #00000099, transparent);
  background-color: #00ffd455;
}

.productModal .Mui-selected {
  /* background-color: greenyellow; */
  color: #fff;
  background: linear-gradient(1deg, #00000099, transparent) !important;
  background-color: #00ffd455 !important;
}

.productModal .MuiTab-root.Mui-selected {
  /* background-color: greenyellow; */
  color: #fff;
  background: linear-gradient(1deg, #00000099, transparent) !important;
  background-color: #ffffff11 !important;
  border-radius: 5px;
}

.css-1gjgmky-MuiToggleButtonGroup-root {
  display: -ms-inline-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-flow: wrap;
  grid-gap: 10px 0px;
  gap: 10px 0px;
  border-radius: 0px !important;
}

description {
  font-size: 12px;
  text-transform: none;
  font-style: italic;
  font-weight: 100;
  opacity: 0.5;
}

.dayNight svg {
  width: 15px;
  height: 15px;
}

.availsCalendarContainer {
  /* background: linear-gradient(45deg, #00ffd422, transparent); */
  /* box-shadow: 0px 1px 10px #00ffd422 inset; */
  border-radius: 10px;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
  background: linear-gradient(0deg, #00000000, #44444455);
  transition: all 1s ease-out !important;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:hover {
  background: linear-gradient(0deg, #00000000, #66666699);
  transition: all 1s ease-out !important;
}

.availsCalendar {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  /* background: #44444499; */
}

availMonth {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: avenir;
  font-weight: 900;
  margin-top: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* background: #111; */
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 2;
  border-bottom: 1px solid #333;
}

.dayOfTheWeekHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 2;
}

.availButtonGroup {
  /* flex-wrap: wrap; */
}

.availButtonDate {
  height: calc(14.285%);
  width: 14.285%;
  position: relative;
  float: left;
  overflow: hidden;
  /* border: 1px solid #ffffff33; */
}

.availButtonDay {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.availButtonNight {
  position: absolute;
  width: 100%;
  bottom: 10px;
  right: 0px;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.availCalendarDate {
}

.availCalendarDayOfTheWeek {
  width: 14.285%;
  text-align: center;
  background: #333;
  color: #ccc;
  font-family: Avenir;
}

.selectFullwidth {
  width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: solid #02ffd4 0px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: solid #02ffd4 0px !important;
}

.summaryTable {
  font-family: Avenir;
}

.summaryTable tr {
  transition: all 1s;
}

.summaryTable th {
  /* border-top: 1px solid #555; */
  text-align: left;
  padding: 2px 5px;
  width: 200px;
  transition: all 0.3s;
  /* border-bottom: 1px solid #555; */
}

.summaryTable td {
  /* border-top: 1px solid #555; */
  text-align: left;
  padding: 2px 5px;
  width: 200px;
  transition: all 0.3s;
  -webkit-animation: tableAppear 600ms ease-in-out;
          animation: tableAppear 600ms ease-in-out;
}

.summaryTable tr:hover {
  background: #33333333;
  transition: all 0.3s;
}

/* .summaryTable td:hover {
  background: #33333333;
  transition: all 0.3s;
} */

@-webkit-keyframes tableAppear {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0;
    margin-top: 250px;
    /* overflow: hidden; */
    /* margin-top: -20px; */
    /* width: 0px; */
    /* filter: blur(0px); */
  }
  20% {
    /* opacity: 0.5; */
    /* margin-top: 0px; */
    /* width: 100%; */
  }

  25% {
  }
  35% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  99% {
  }
  100% {
    opacity: 1;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    margin-top: 0px;
  }
}

@keyframes tableAppear {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    opacity: 0;
    margin-top: 250px;
    /* overflow: hidden; */
    /* margin-top: -20px; */
    /* width: 0px; */
    /* filter: blur(0px); */
  }
  20% {
    /* opacity: 0.5; */
    /* margin-top: 0px; */
    /* width: 100%; */
  }

  25% {
  }
  35% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  99% {
  }
  100% {
    opacity: 1;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    margin-top: 0px;
  }
}

/* General Forms */

.MuiClock-root {
  zoom: 0.3;
  transition: All 0.3s;
  position: relative;
}

.MuiClock-root:hover {
  zoom: 1;
  transition: All 0.3s;
}

.chargeAuto label {
  font-size: 12px;
  width: 70vw;
}
.chargeAuto {
  position: relative;
  display: block;
  /* float: left; */
  margin-top: 0px;
}

.phoneInput span {
  color: #00ffd4;
}

input:autofill {
  color: white !important;
  background: none !important;
  box-shadow: 0 0 0 100px inset #222 !important;
}

.VHloginDialog {
  /* background-color: "linear-gradient(0deg,#000, #fff)" !important; */
  /* background: orange; */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* background-image: none; */
  background: linear-gradient(1deg, #111, #333);
  /* color: orange !important; */
  /* background-image: none; */
}

.availsCard.css-bj2p8a-MuiPaper-root-MuiCard-root {
  position: fixed;
  left: 320px;
  height: auto;
  max-height: 30vh;
  overflow-y: scroll;
  width: 400px;
  padding: 20px;
  /* background: linear-gradient(0deg, #222, transparent); */
  background: linear-gradient(0deg, #ffffff11, #ffffff22);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(30px);
  border-radius: 20px;
  /* border: 3px solid #00ffd499; */
  box-shadow: 10px 10px 10px black;
  -webkit-animation: tableAppear 600ms ease-in-out;
          animation: tableAppear 600ms ease-in-out;
}

.availsSelectArtist {
  padding: 20px;
  background: linear-gradient(0deg, #111, #333);
  border-radius: 10px;
  margin-top: 20px;
}

.bookingFormModal {
  position: absolute !important;
  /* top: 100px !important; */
  width: 90%;
  /* max-width: 1160px; */
  height: 80vh;
  /* max-height: 720px; */
  /* left: 10% !important; */
  overflow: hidden;
  /* padding: 30px; */
  background: linear-gradient(0deg, #222222ee, #444444ee);
  font-size: 14px;
  margin: auto;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.artistImageBookingForm {
  width: 300px !important;
  height: auto !important;
  overflow: hidden;
}

.bookingSelect {
  padding: 20px;
  /* background: linear-gradient(0deg, #222, #333); */
  background: none;
  /* margin: 20px; */
  border-radius: 20px;
  /* padding-bottom: 40px; */
}

.bookingSelect .venueImage {
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  float: left;
  margin-right: 20px;
}

.bookingSelect table tr td:nth-child(1) {
  color: #777;
  font-weight: 700;
  width: 100px;
}

.bookingSelect .AccordionSummary {
  background: none;
  width: 100%;
}

.bookingSelect .AccordionSummary .infoA {
  width: 70%;
  float: left;
}

.bookingSelect .AccordionSummary .date {
  display: flex;
  justify-content: end;
}

.bookingSelect .AccordionSummary .rider {
  position: absolute;
  display: block;
  right: 0px;
  bottom: 10px;
}

.bookingSelect .infoB .status {
  float: right;
  font-size: 11px;
  margin-top: -10px;
}

.bookingSelect .css-qoi9-MuiPaper-root-MuiAccordion-root:first-of-type {
  background: linear-gradient(0deg, #222, #333);
}

.bookingSelect .artist {
  position: absolute;
  top: -5px;
  left: -5px;
  -webkit-filter: drop-shadow(2px 4px 6px black);
          filter: drop-shadow(2px 4px 6px black);
}

.bookingSelect .agreement {
  position: absolute;
  right: 60px;
  bottom: 17px;
}

.bookingSelect .agreement.attention {
  bottom: 11px;
}
.bookingSelect .agreement.attention svg {
  background: #00ffd499;
  padding: 4px;
  zoom: 1.5;
  color: #00ffd4 !important;
  -webkit-animation: colorAnimation 6s infinite;
          animation: colorAnimation 6s infinite;
}

@-webkit-keyframes colorAnimation {
  0% {
    background-color: #ffffff00;
  }
  30% {
    background-color: #ffffff33;
  }
  52% {
    background-color: #ffffff00;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  70% {
    background-color: #ffffff33;
  }
  100% {
    background-color: #00bbff00;
  }
}

@keyframes colorAnimation {
  0% {
    background-color: #ffffff00;
  }
  30% {
    background-color: #ffffff33;
  }
  52% {
    background-color: #ffffff00;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  70% {
    background-color: #ffffff33;
  }
  100% {
    background-color: #00bbff00;
  }
}

.notSignedIn {
  font-size: 13px;
  /* position: absolute; */
  float: right;
  font-family: Avenir;
  right: -7px;
  font-weight: 800;
  top: -1px;
  color: #00ffd4;
  margin-top: 10px;
}

.agreementCheck {
  color: #00ffd4;
  border-radius: 50%;
  border: 2px solid #00ffd4;
  display: inline-flex;
}

.confirmed-row {
  /* background-color: #00800044; */
  background-color: #00ffd405;
}

.awaiting-artist-row {
  /* background-color: #ffa50044; */
  background-color: #ffa50011;
  color: #aaa;
}

.pending-request-row {
  /* background-color: #ff000044; */
  background-color: #00ffd415;
  color: #aaa;
}

.history-row {
  /* background-color: #ff000044; */
  color: green;
}

.css-a7h9s6-MuiDataGrid-root .MuiDataGrid-row:hover {
  transition: all 1s;
  background: red;
}

.circular-div {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px; /* Or whatever size you want */
  height: auto; /* Make sure width and height are the same to create a perfect circle */
  max-height: 200px;
  border-radius: 10%; /* This makes the div circular */
  overflow: hidden; /* This ensures the image doesn't overflow the circular div */
  box-shadow: 5px 5px 15px black;
}

.circular-div img {
  object-fit: cover;

  /* min-height: 200px;
  max-height: 140%;
  width: auto; */
  /*
  max-width: 120%; 
  max-height: 200%; */
}

.circular-div-backBlur {
  z-index: -1;
  position: absolute;
  -webkit-filter: blur(80px);
          filter: blur(80px);
  left: -150px;
  top: -100px;
  opacity: 1;
  -webkit-animation: floater 10s infinite;
          animation: floater 10s infinite;
}

@-webkit-keyframes floater {
  20% {
    left: -20px;
    opacity: 0.5;
  }
}

@keyframes floater {
  20% {
    left: -20px;
    opacity: 0.5;
  }
}

.bookingFormModal {
  box-shadow: 0px 0px 40px black;
  /* border: 5px solid #ffffff44; */
  transition: all 2s;
  /* animation: tableAppear 300ms; */
}

#invoice {
  font-family: Avenir, sans-serif !important;
  padding: 0px 4mm !important;
  width: 210px !important;
  height: 297px;
  color: black !important;
  background: white !important;
  font-size: 3px !important;
}

.invoiceImg {
  position: relative;
  top: 15px;
  left: 0px;
  width: 40px;
}

.invoiceTable {
  width: 100% !important;
}

.invoiceTable th {
  margin-top: 10px;
  text-transform: uppercase;
  width: 150px !important;
  border-top: 10px solid transparent;
  border-bottom: 1px solid black;
}

.invoiceTable td {
  width: 100px;
}

table.travelData td {
  width: 130px;
  height: 29px;
  text-align: center;
  font-weight: 100;
}

table.travelData {
  background: linear-gradient(0deg, #111, #333);
  border-radius: 5px;
  padding: 20px;
}

table.travelData thead {
  padding: 10px;
  text-align: center;
  font-weight: 600;
}

.travelDataPoint {
  height: 20px;
  width: 20px;
  border-radius: 15px;
  background-color: #ffffff55;
  -webkit-filter: blur(0.3px);
          filter: blur(0.3px);

  /* animation: colorAnimationB 10s infinite; */
}

.pollTravelDataPoint {
  width: 20px;
  height: 20px;
  background: #00ffd455;
  border-radius: 50%;
  text-align: center;
  padding: 3px;
  /* animation: fifthElement 10s infinite; */
}

@-webkit-keyframes fifthElement {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    background: #00ffd4;
    width: 70px;
    height: 1px;
  }
  5% {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    background: #00ffd4;
    width: 70px;
    height: 1px;
    rotate: -90deg;
  }
  10% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background: #00ffd4;
    width: 20px;
  }

  52% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background-color: transparent;
    rotate: 90deg;
  }

  100% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    rotate: 180deg;
  }
}

@keyframes fifthElement {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
    background: #00ffd4;
    width: 70px;
    height: 1px;
  }
  5% {
    -webkit-filter: blur(3px);
            filter: blur(3px);
    background: #00ffd4;
    width: 70px;
    height: 1px;
    rotate: -90deg;
  }
  10% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background: #00ffd4;
    width: 20px;
  }

  52% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background-color: transparent;
    rotate: 90deg;
  }

  100% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    rotate: 180deg;
  }
}

.lastTravelDataPoint {
  -webkit-animation: colorAnimationB 3s infinite;
          animation: colorAnimationB 3s infinite;
  width: 40px !important;
  height: 40px !important;
}

@-webkit-keyframes colorAnimationB {
  0% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    background: #00ffd4;
    border: 0px dotted red;
  }

  52% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background-color: transparent;
    rotate: 90deg;
    border: 5px dotted red;
  }

  100% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    rotate: 180deg;
    border: 1px dotted red;
  }
}

@keyframes colorAnimationB {
  0% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    background: #00ffd4;
    border: 0px dotted red;
  }

  52% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    background-color: transparent;
    rotate: 90deg;
    border: 5px dotted red;
  }

  100% {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    rotate: 180deg;
    border: 1px dotted red;
  }
}

.travelDataMap {
  border: 0px solid white;
  background: linear-gradient(0deg, #222, #333);
  padding: 10px;
  border-radius: 5px;
}

.travelDataCircleA {
  z-index: 100000;
}

.invoiceButtons {
  position: absolute;
  bottom: 20px;
  color: #fff !important;
}

.selectedDateItem itemImage {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 10px;
  display: block;
}

.selectedDateItem img {
  min-width: 60px;
  min-height: 60px;
  max-height: 100px;
  max-width: 100px;
  object-fit: fill;
  object-position: -20px center;
  border-radius: 5px;
}

.selectedDateItem itemArtist {
  font-size: 15px;
  font-weight: 600;
}

/* .selectedDateItem {
  animation: tableAppear 500ms;
} */

.selectedDateModal {
  -webkit-animation: tableAppear 100ms;
          animation: tableAppear 100ms;
}

.selectedDateItem td {
  padding: 10px;
}

.selectedDateItem th,
.selectedDateItem td {
  text-align: center;
  padding: 10px 10px;
}

.selectedDateItem,
.selectedDateItem th {
  font-size: 12px;
}

.standardModal {
  width: 50%;

  /* max-height: 80%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 8%;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background: #ffffff00;
  background: linear-gradient(0deg, #22222222, #55555555);
  /* border: 1px solid #00ffd433; */
  box-shadow: 2px 2px 10px black;
  overflow-y: scroll;
  padding: 20px;
  transition: all 1s;
}

.requestAvailsCalendar {
  background: linear-gradient(0deg, #222, #555);
}

.product {
  font-size: 10px;
  position: absolute;
  top: 170px;
  opacity: 1;
  /* backdrop-filter: blur(30px); */
  width: 100%;
  left: 0;
  color: #cccccc99;
}

.product:hover {
  color: #fff;
}

#root {
  width: 100vw;
  height: 100vh;
  /* border: 4px dashed orange; */
}

@-webkit-keyframes growBorder {
  0% {
    offset-distance: 0; /* Start the border at 0% length */
  }
  100% {
    offset-distance: 100%; /* End the border at 100% length */
  }
}

@keyframes growBorder {
  0% {
    offset-distance: 0; /* Start the border at 0% length */
  }
  100% {
    offset-distance: 100%; /* End the border at 100% length */
  }
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: #00000020;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

.workInProgress {
  display: none;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 13px;
  z-index: 10000;
  -webkit-animation: UCbar 3000ms ease-in-out;
          animation: UCbar 3000ms ease-in-out;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2FUC-bottom.png?alt=media&token=e00a22c4-532a-47df-b049-588f89ac601a);
}

@-webkit-keyframes UCbar {
  0% {
    bottom: -30px;
  }
  80% {
    bottom: -30px;
  }
  100% {
    bottom: 0px;
  }
}

@keyframes UCbar {
  0% {
    bottom: -30px;
  }
  80% {
    bottom: -30px;
  }
  100% {
    bottom: 0px;
  }
}

.workInProgressLabel {
  display: none;
  position: fixed;
  bottom: -20px;
  left: 208px;
  width: 80px;
  height: auto;
  z-index: ;
  -webkit-animation: UnderConstruction 7500ms ease-in-out;
          animation: UnderConstruction 7500ms ease-in-out;
  z-index: 10001;
}

@-webkit-keyframes glitch {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
  25% {
    -webkit-transform: translate(-5px, 5px);
            transform: translate(-5px, 5px);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: translate(5px, -5px) rotate(2deg);
            transform: translate(5px, -5px) rotate(2deg);
    opacity: 0.6;
  }
  75% {
    -webkit-transform: translate(-5px, 5px) rotate(-2deg);
            transform: translate(-5px, 5px) rotate(-2deg);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes glitch {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
  25% {
    -webkit-transform: translate(-5px, 5px);
            transform: translate(-5px, 5px);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: translate(5px, -5px) rotate(2deg);
            transform: translate(5px, -5px) rotate(2deg);
    opacity: 0.6;
  }
  75% {
    -webkit-transform: translate(-5px, 5px) rotate(-2deg);
            transform: translate(-5px, 5px) rotate(-2deg);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes UnderConstruction {
  0% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    opacity: 0;
    -webkit-filter: blur(100px);
            filter: blur(100px);
  }
  10% {
    -webkit-transform: translateY(-100vh) rotateX(-900deg) rotateY(0deg);
            transform: translateY(-100vh) rotateX(-900deg) rotateY(0deg);
    -webkit-filter: blur(25px);
            filter: blur(25px);
  }
  50% {
    width: 80px;
    opacity: 1;
    -webkit-transform: translateY(0px) rotateX(0deg) rotateY(360deg);
            transform: translateY(0px) rotateX(0deg) rotateY(360deg);
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  100% {
    -webkit-transform: translateY(0px) rotateX(0deg) rotateY(360deg);
            transform: translateY(0px) rotateX(0deg) rotateY(360deg);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  /* 0% {
    left: -10%;
    rotate: -190deg;
    bottom: -50px;
  }
  20% {
    bottom: -20px;
    rotate: 0deg;
  }
  40% {
    rotate: 0deg;
    bottom: -20px;
  }
  50% {
    left: 100%;
    bottom: -50px;
    rotate: 90deg;
  }
  100% {
    left: 100%;
    bottom: -50px;
    rotate: 90deg;
  } */
}

@keyframes UnderConstruction {
  0% {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    opacity: 0;
    -webkit-filter: blur(100px);
            filter: blur(100px);
  }
  10% {
    -webkit-transform: translateY(-100vh) rotateX(-900deg) rotateY(0deg);
            transform: translateY(-100vh) rotateX(-900deg) rotateY(0deg);
    -webkit-filter: blur(25px);
            filter: blur(25px);
  }
  50% {
    width: 80px;
    opacity: 1;
    -webkit-transform: translateY(0px) rotateX(0deg) rotateY(360deg);
            transform: translateY(0px) rotateX(0deg) rotateY(360deg);
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  100% {
    -webkit-transform: translateY(0px) rotateX(0deg) rotateY(360deg);
            transform: translateY(0px) rotateX(0deg) rotateY(360deg);
    -webkit-filter: blur(0);
            filter: blur(0);
  }

  /* 0% {
    left: -10%;
    rotate: -190deg;
    bottom: -50px;
  }
  20% {
    bottom: -20px;
    rotate: 0deg;
  }
  40% {
    rotate: 0deg;
    bottom: -20px;
  }
  50% {
    left: 100%;
    bottom: -50px;
    rotate: 90deg;
  }
  100% {
    left: 100%;
    bottom: -50px;
    rotate: 90deg;
  } */
}

.paymentStatus {
  padding: 10px;
  background: #333;
}

.productsGraph {
  min-width: 100px;
  max-width: 300px;
  /* border-left: 1px solid #555;
  border-right: 1px solid #555; */
  padding: 5px;
  /* background: #33333399; */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin-right: 10px;
  border-radius: 5px;
}

.productBar {
  /* width: 15% !important; */
  width: 15px;
  color: #f0bd5f4b;
}

.bookingMap {
  width: 100%;
  overflow: hidden;
}

.linkIcon {
  position: absolute;
  right: 0px;
}

.ticketSales {
  font-family: Avenir;
  position: absolute;
  /* bottom: -6px;
  left: 0px; */
  top: 120px;
  right: 0px;
  z-index: 1;
  opacity: 1;
  font-size: 12px;
}

.singleBookingTickets {
  position: relative;
}

.ticketPie {
  float: left;
  opacity: 0.8;
}

.ticketSaleNumber {
  opacity: 0.2;
  float: right;
}

.bookingFormPie {
  z-index: 10;
}

.showSalesInside {
  font-size: 35px;
  opacity: 0.4;
  font-weight: 900;
  -webkit-transform: translate(0%, -18%);
          transform: translate(0%, -18%);
  text-align: center;
}

.singleBookingTickets {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.weeklySalesChart {
  -webkit-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
  opacity: 0.5;
}

.chart-container {
  width: 400px;
  margin: 20px auto;
  padding: 5px;
}

.bar-chart {
  display: flex;
  /* justify-content: space-between; */
}

.bar {
  height: 400px;
  flex: 0 0 auto;
  width: 10px; /* Adjust the width of the bars */

  background-color: orange; /* Bar color */
  margin-right: 10px; /* Adjust the spacing between bars */
  position: relative;
  z-index: 2;
  transition: "width" 1s;
  /* animation: Barko 1000ms ease-in-out; */
}

@-webkit-keyframes Barko {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  80% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes Barko {
  0% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  80% {
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

.bar:hover {
  background: #00ffd4 !important;
}

.bar-label {
  text-align: center;
  margin-top: 5px;
}

.xAxis {
  width: 200px;
  height: 150px;
  border-bottom: 1px dashed #00ffd444;
  position: absolute;
  bottom: 24px;
  border-left: 1px dashed #00ffd444;
  left: 3px;
  z-index: 1;
}

.BatchEditTools {
  padding: 10px;
  margin-top: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.BatchEditTools svg:hover {
  color: #00ffd499;
}

.inComingRequests:hover {
  mix-blend-mode: multiply;
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
}

.inComingRequests {
  transition: all 0.1s;
}

@-webkit-keyframes spinIn {
  from {
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes spinIn {
  from {
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  to {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

.timeSelector {
  color: transparent !important;
}
.timeSelector p {
  color: #00ffd499 !important;
  margin-top: 20px;
}

.CO2e {
  opacity: 0.4;
  font-weight: 100;
}

.dataGridTime {
  color: #00ffd499;
  -webkit-animation: newValue 0.4s;
          animation: newValue 0.4s;
}

@-webkit-keyframes newValue {
  from {
    -webkit-filter: blur(2px);
            filter: blur(2px);
  }
  to {
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes newValue {
  from {
    -webkit-filter: blur(2px);
            filter: blur(2px);
  }
  to {
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

.selectedRow {
  opacity: 0.3;
}

.unselectedRow {
  opacity: 1;
}

.offerAccordionTable {
  margin-left: 20px;
  text-align: left;
  height: 0px;
  line-height: 20px;
  width: 100%;
}

.offerAccordionTable .venue {
  font-size: 20px;
  font-weight: 800;
  height: 35px;
}

.offerAccordionTable .artist {
  font-size: 20px;
  font-weight: 100;
}

.offerAccordionTable td.agreementId {
  opacity: 0.4;
  font-size: 15px;
  position: absolute;
  right: 5px;
  top: 0px;
}

.offerAccordionTable td.readyToSign {
  border-radius: 50px;
  /* background: #ffa50099; */
  border: 1px solid #ffffff55;
  font-size: 15px;
  position: absolute;
  right: 65px;
  top: 20px;
  padding: 10px 10px;
  color: orange;
}

.BatchEditTools {
  justify-content: center;
  /* background: linear-gradient(#44444499, transparent); */
  padding: 20px;
  display: flex;
  grid-gap: 35px;
  gap: 35px;
}

textarea.Textarea {
  background: #292929;
  color: white;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  border: none;
}

.overviewTable td {
  padding: 0px 15px;
  text-align: left;
  font-size: 12px;
}

.overviewTable td:nth-child(1) {
  color: #777;
  text-align: left;
}

.overviewTable td:nth-child(2) {
  color: #eee;
  text-align: left;
}

.overviewTable th {
  padding: 0px 15px;
  text-align: left;
  font-size: 12px;
  background: #333;
  font-weight: 100;
}

.overviewTable thead {
  width: 100%;
}

pricingicon {
  float: left;
  color: #444;
}

pricingexcluded {
  color: #ffffff;
}

pricingexcluded {
  color: #ffffff55;
}

.overviewTable {
  /* width: inherit; */
  margin-top: 10px !important;
  margin-bottom: 30px !important;
}

.overviewTable table {
  width: 700px;
}

.overviewTable td {
  width: 50%;
  vertical-align: center;
  text-align: left;
}

/* .overviewTable tr:nth-child(odd) {
  background-color: #ffffff05;
}
.overviewTable tr:nth-child(even) {
  background-color: #ffffff00;
} */

.productFeatures .overviewTable input {
  background: #222;
  border: none;
  color: white !important;
  border-radius: 5px;
  padding: 5px;
  width: 90px;
  text-align: center;
}

.productFeatures .overviewTable input:focus {
  font-size: 15px;
  background: #ffffff22;
  color: #fff;
}

.featureHeader {
  background: #333;
  text-indent: 20px;
}

.optionHeader {
  color: #ffffffbb !important;
  font-weight: 900;
  font-size: 15px !important;
}

.MuiToggleButtonGroup-root {
  padding: 20px;
}

.productGraphBlock {
  padding: 4px;
  font-weight: 900;
  border-right: 1px solid #222;
  border-radius: 2px;
}

.productGraphBlock:hover {
  opacity: 0.3;
}

.blockDate {
  top: 430px;
  position: absolute;
  background: #444;
  padding: 0px 8px;
  border-radius: 3px;
}

.blockDate:hover {
  opacity: 0.4;
}

.offerOptionals {
  text-align: left;
}

.categoryIcon {
  font-size: 50px !important;
  /* background: #222; */
  color: #00ffd4;
  color: #777;
  border-radius: 50%;
  /* border: 1px solid white; */
  padding: 5px;
  z-index: 1;
  margin-top: 0px;
}

.categoryListIcon {
  zoom: 0.6;
  margin-right: 10px;
}

.bookingSize {
  zoom: 0.7;
  position: absolute;
  bottom: 20px;
  left: 0px;
  z-index: 100;
  padding: 5px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: 30px;
  border-radius: 10px;
}

.productSummaryDiv {
  /* position: absolute; */
  -webkit-animation: slideinRight 1s;
          animation: slideinRight 1s;
}

@-webkit-keyframes slideinRight {
  from {
    opacity: 0;
    margin-left: 20px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  to {
    opacity: 1;
    margin-left: 0px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes slideinRight {
  from {
    opacity: 0;
    margin-left: 20px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
  to {
    opacity: 1;
    margin-left: 0px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

.transactionTable {
  background: #55555522;
  padding: 10px;
}

.transactionTable thead {
  background: #555;
}

.transactionTable th,
.transactionTable td {
  padding: 10px;
  width: 150px;
  border-bottom: 1px solid #555;
}

.ledgerTable {
  width: 100%;
  background: #55555522;
  padding: 10px;
}

.ledgerTable thead {
  background: #555;
}

.ledgerTable th,
.ledgerTable td {
  padding: 10px;
  width: 150px;
  border-bottom: 1px solid #555;
}

.blockContainer {
  font-family: Arial, Helvetica, sans-serif;
  background: linear-gradient(#222, #22222200);
  border-radius: 10px;
}

.premises {
  /* height: 100vh; */
}

.sustainability {
  font-family: Arial, Helvetica, sans-serif !important;
}

.sustainability h4 {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  font-weight: 900;
  text-transform: uppercase;
  color: #00ffd4;
}

h7 {
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.visualData {
  /* background: linear-gradient(#55555522, #55555505); */
  padding: 15px 10px;
  border-radius: 5px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: top;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  /* animation: slideinRight 0.4s; */
  -webkit-animation: snitch 0.4s;
          animation: snitch 0.4s;
}

.CO2eDisplay {
  color: #ffffff99;
  font-size: 12px;
  padding: 0px 5px 5px 5px;
}

.dataInput {
  justify-content: flex-end;
  /* animation: slideinRight 1s; */
}

@-webkit-keyframes snitch {
  0% {
    opacity: 0;
    margin-left: 5px;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
  50% {
    opacity: 00.5;
    -webkit-filter: blur(0.5px);
            filter: blur(0.5px);
  }
  100% {
    opacity: 1;
    margin-left: 0px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes snitch {
  0% {
    opacity: 0;
    margin-left: 5px;
    -webkit-filter: blur(1px);
            filter: blur(1px);
  }
  50% {
    opacity: 00.5;
    -webkit-filter: blur(0.5px);
            filter: blur(0.5px);
  }
  100% {
    opacity: 1;
    margin-left: 0px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

selectbutton {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  display: contents;
}

selectbutton:hover {
  color: #00ffd499;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.addEditButton {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  opacity: 0.3;
  margin-top: -10px;
}

.tabstyle {
  border-bottom: 1px solid #ffffff55;
}

.vd-map {
  /* background: radial-gradient(#00ffd455, #00ffd411); */
  border-radius: 10px;
  min-height: 300px;
  min-width: 300px;
  /* height: 100%; */
  height: 35vh;
  width: 100%;
  float: right;
  overflow: hidden;
}

/* GridNet.css */
.grid-net {
  position: absolute;
  display: grid;
  border: 1px solid #00000044; /* Add a border for visualization */
  top: -25px;
  pointer-events: none;
}

.grid-cell {
  border: 1px solid #00000022; /* Add borders to grid cells */
  z-index: 1;
}

.grid-cell:hover {
  z-index: -1;
}

.sustainabilityReport {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 1px solid #444;
  padding: 5px;
  color: #666;
  width: 100%;
  font-size: 12px;
}

.sustainabilityReport table {
  opacity: 0.9;
  width: 100%;
}

.sustainabilityReport table td {
  color: #00ffd499 !important;
}

.sustainabilityReport tr {
  border-bottom: 1px solid #444;
}

.sustainabilityReport th {
  color: #666;
  padding: 0px 5px;
  background: "#222" !important;
  border-bottom: 1px solid #444;
}
.sustainabilityReport td {
  padding: 0px 5px;
  background: "red" !important;
}

.sustainabilityReport .headerIcons {
  text-align: center;
  zoom: 0.8;
  font-size: 20px;
}
.sustainabilityReport .travelImpact {
  text-align: center;
}

.travelReport {
  text-align: center;
  position: absolute;
  bottom: 1px;
  width: 100%;
  /* pointer-events: none; */
  background: #000000bb;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.travelReport td {
  color: #00ffd499;
}

.eventTravelDataMap {
  border: 2px solid #333;
  border-radius: 10px;
  height: 45vh;
  overflow: hidden;
}

.PDFsustainabilityReport {
  /* transform: scale(1); */
  border: 0.1pt solid #444;
  padding: 5px;
  color: #666;
  /* width: 100%; */
  /* transform: scale(0.4); */
  font-size: 1.5pt;
  width: -440pt;
  left: 0;
  top: 0;
}

.PDFsustainabilityReport table {
  opacity: 0.9;
  width: 100%;
}

.PDFsustainabilityReport table td {
  color: #000;
}

.PDFsustainabilityReport tr {
  border-bottom: 0.1pt solid #444;
}

.PDFsustainabilityReport th {
  padding: 0px 5px;
  background: "#222" !important;
  border-bottom: 0.1pt solid #444;
}
.PDFsustainabilityReport td {
  padding: 0px 5px;
  background: "red" !important;
}

.climateDataSheet {
  height: 70vh;
  width: 92vw;
  overflow: scroll;
}

.climateDataSheet thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #111;
  font-size: 12px;
  z-index: 1;
  box-shadow: 0px 0px 15px 10px black;
  margin-bottom: 20px;
}

.climateDataSheet th {
  padding: 10px 10px;
}

.climateDataSheet input {
  background: #00ffd455;
  border-radius: 4px;
  border: none;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 900;
  line-height: 34px;
  padding: 5px;
  width: 160px;
}

.climateDataSheet textarea {
  background: #00ffd455;
  border-radius: 4px;
  border: none;
  text-align: center;
  color: white;
  font-size: 12px;
  font-weight: 900;
  line-height: 17px;
  padding: 5px;
  width: 160px;
  margin-top: 7px;
}

.bookingImagePick {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  border-radius: 5px;
  /* padding: 7px 0px; */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 3px 4px 21px -2px black;
  background: #333333;
  border: 10px solid #222;
}

.bookingImagePick input {
  width: 100%;
}

/* .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin-top: 10px;
  width: 50%;
  position: sticky;
  left: -0px;
} */

.bookingFormOptionsTable td {
  height: 16px;
  font-size: 12px;
  opacity: 0.9;
}

.bookingFormOptionsTable input[type="number"] {
  background: transparent;
  border: none;
  color: white;
  width: 70px;
  text-align: center;
  border-radius: 5px;
}

.bookingFormOptionsTable:hover input[type="number"] {
  background: #00ffd422;
}

.optiontd {
  width: 200px;
}

.responsetd {
  width: 100px;
  color: #555;
}

.remindertd {
  cursor: pointer;
  width: 55px;
  background: linear-gradient(#00ffd444, #00ffd422);
  border-radius: 5px;
  text-align: center;
  color: #00ffd4;
  border-bottom: 1px solid #00ffd411;
  border-top: 1px solid #00ffd411;
}

.tableH7 {
  border-bottom: 1px solid rgb(0 255 212 / 9%);
}

table.smallTable {
  width: 100% !important;
  /* table-layout: fixed; */
  /* display: table; */
}

.smallTable label {
  color: #00ffd4;
  font-size: 12px;
  font-weight: 900;
  display: none;
}

.smallTable .vatSelector {
  height: 30px;
}

.smallTable thead {
  border-bottom: 1px solid #00ffd4;
}

.smallTable td {
  width: auto;
  /* background: rgba(255, 192, 203, 0.35); */
}

/* From Here */
.smallTable input {
  color: white;
  width: 100% !important;
  background: #ffffff00;
  border: none;
  border-radius: 5px;
}

.smallTable input:hover {
  background: #00ffd433;
}

.smallTable input:focus {
  color: black;
  font-weight: 900;
  background: #00ffd4 !important;
  border: none !important;
  border-radius: 5px;
  outline: none;
}

/* .smallTable .feature {
  background: transparent;
  padding: 0px 10px;
  border-radius: 5px;
  transition: all 3.5s ease-out !important;
} */

.bookingFeatureEditorTop {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  -webkit-backdrop-filter: opacity(0);
          backdrop-filter: opacity(0);
  z-index: 2;
  background: #333333;
  height: 25px;
}

.bookingFeatureEditor thead {
  position: -webkit-sticky;
  position: sticky;
  top: 22px;
  background: #222;
  -webkit-backdrop-filter: opacity(0) !important;
          backdrop-filter: opacity(0) !important;
  z-index: 2;
  display: table-row-group;
  border-bottom: 1px solid #00ffd499;
}

.bookingFeatureEditor tbody {
  /* background: #00ffd422; */
  position: relative;
  max-height: 37vh;
  overflow: scroll;
  /* display: table-caption; */
  display: table-row-group;
  z-index: 1;
}

.smallTable .bookingFeatureTotal {
  background: transparent;
  padding: 0px 10px;
  border-radius: 5px;
  transition: all 3.5s ease-out !important;
}

.smallTable h7 {
  opacity: 0.4;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.toggleSelect {
  width: 50%;
}

.toggleSelect:hover {
  background: #00ffd433;
  color: #222;
}

@-webkit-keyframes rowAppear {
  0% {
    margin-top: -5px;
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  100% {
    margin-top: 0px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

@keyframes rowAppear {
  0% {
    margin-top: -5px;
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }

  100% {
    margin-top: 0px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
  }
}

img {
  -webkit-animation: imgAppear 300ms ease-in-out;
          animation: imgAppear 300ms ease-in-out;
}

.bookingFormTabsMenu {
  position: relative;
}

.bookingFormGeneral {
  width: 100% !important;
}

.css-4eryzr-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}

.signatures {
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
}

.awaitingCustomerSignature {
  font-size: 12px;
  color: #00ffd455;
  margin-right: 20px;
  padding: 20px;
  border: 1px dashed;
  height: 60px;
  width: 49%;
}

.awaitingVenueSignature {
  font-size: 12px;
  color: #00ffd455;
  margin-right: 20px;
  padding: 20px;
  border: 1px dashed;
  height: 60px;
  width: 49%;
  -webkit-animation: blink 3s infinite;
          animation: blink 3s infinite;
}
.awaitingVenueSignature:hover {
  color: #00ffd4 !important;
}

@@media (min-width: 800) {
  .quickSpecs {
    transition: zoom 0.5s;
    zoom: 1.2;
  }
}

@media (min-width: 1200px) {
  .quickSpecs {
    transition: zoom 0.5s;
    zoom: 1.5;
  }
}

.quickSpecsBox {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 800px;
  background: white;
  border: 2px solid #000;
  color: black;
  overflow: scroll;
  padding: 20px;
}

@-webkit-keyframes blink {
  0% {
    color: #00ffd4;
    border: 1px solid;
  }
  50% {
    color: #00ffd455;
    /* border: 1px dashed; */
  }

  100% {
    color: #00ffd4;
    border: 1px solid;
  }
}

@keyframes blink {
  0% {
    color: #00ffd4;
    border: 1px solid;
  }
  50% {
    color: #00ffd455;
    /* border: 1px dashed; */
  }

  100% {
    color: #00ffd4;
    border: 1px solid;
  }
}

@media print {
  .no-page-break {
    page-break-inside: avoid;
  }
}

.page-break {
  page-break-before: always;
}

.loginButton {
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(0deg, #00ffd411, #00ffd411);
  font-size: 12px;
  font-family: avenir;
  font-weight: 900;
  vertical-align: middle;
  padding: 10px;
  height: 30px;
  line-height: 10px;
  margin-top: 6px;
}
.loginButton:hover {
  background: linear-gradient(0deg, #00ffd422, #00ffd411);
}

.agreementVenueLogo {
}

/* HIDING SCROLLBARS! */

.upcomingEventsContainer {
  overflow: auto; /* Enable scrolling */
  /* margin-right: -100px; 
  padding-right: 100px;  */
}

.upcomingEventsContainer::-webkit-scrollbar {
  display: none;
}

.upcomingEventsContainer {
  scrollbar-width: none;
}

.outlet {
  overflow: auto; /* Enable scrolling */
  margin-right: -100px; /* Effectively push scrollbar out of view */
  padding-right: 100px; /* Ensure content padding matches negative margin */
}

.outlet::-webkit-scrollbar {
  display: none;
}

.outlet {
  scrollbar-width: none;
}

.standardModal {
  overflow: auto; /* Enable scrolling */
}

.standardModal::-webkit-scrollbar {
  display: none;
}

.standardModal {
  scrollbar-width: none;
}

.productsWithFeatures {
  overflow: auto; /* Enable scrolling */
  height: 60vh;
}

/* .productsWithFeatures::-webkit-scrollbar {
  display: none;
}

.productsWithFeatures {
  scrollbar-width: none;
} */

.productModal {
  overflow: auto; /* Enable scrolling */
}

.productModal::-webkit-scrollbar {
  display: none;
}

.productModal {
  scrollbar-width: none;
}

.productFeatures {
  overflow: auto; /* Enable scrolling */
}

.productFeatures::-webkit-scrollbar {
  display: none;
}

.productFeatures {
  scrollbar-width: none;
}

.additional {
  cursor: pointer;
  margin-left: 0px;
  transition: all 0.5s;
}
.additional:hover {
  /* margin-left: 5px; */
  transition: all 0.1s;
  box-shadow: 0px 0px 5px 5px #00ffd422;
}

.uploadInput {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid #00ffd499;
  border-radius: 5px;
  height: 29px;
  vertical-align: middle;
  margin-right: 15px;
}

.MonthNavigator {
  /* position: absolute; */

  z-index: 10;
  background: #111;
}

.venueImages {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  flex-wrap: wrap;
}

.venueImages img {
  padding: 10px;
}

.imageUploaded {
  position: relative;
  width: 90px;
  display: flex;
  justify-content: center;
  background: #00ffd411;
  border-radius: 5px;
  vertical-align: middle;
}
.removeImage {
  position: absolute;
  color: #00ffd466;
  bottom: -20px;
  cursor: pointer;
}

.removeImage:hover {
  color: #00ffd4aa;
}

.pickDates {
  overflow: scroll;
  /* backdrop-filter: blur(30px); */
  background-color: none !important;
  /* background: linear-gradient(0deg, #11111144, #33333399); */
}

.pickDates .day {
  background: #111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickDates .day:hover {
  will-change: transform;
  /* background: #00ffd444 !important; */
  border: 1px dashed #00ffd499;

  /* animation: radialPulse 0.5s infinite; */
}

.pickDates .day:active {
  /* background: #00ffd4 !important; */
  border: 1px solid #00ffd4;
  background: #00ffd499;
  /* animation: radialPulse 0.5s reverse infinite; */
}

@-webkit-keyframes radialPulse {
  0% {
    -webkit-transform: rotate(0.5);
            transform: rotate(0.5);
  }
  100% {
    -webkit-transform: rotate(1);
            transform: rotate(1);
  }
}

@keyframes radialPulse {
  0% {
    -webkit-transform: rotate(0.5);
            transform: rotate(0.5);
  }
  100% {
    -webkit-transform: rotate(1);
            transform: rotate(1);
  }
}

.stickyYear {
  position: -webkit-sticky;
  position: sticky;
  top: 300px;
  /* top: 0px;
  background: #111; */
  z-index: 100;
  padding: 10px;
  border-bottom: 1px solid #00ffd4;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
}

body {
  font-family: Arial !important;
}

.agreementAction {
  cursor: pointer;
}

.addBookingsYear {
  display: flex;
  width: 100% !important;
  position: relative;
}

@keyframes radialPulse {
  0% {
    opacity: 1;
    background: linear-gradient(#33333311, #77777799);
    box-shadow: 0px 0px 10px #777 inset;
  }

  50% {
    opacity: 1;
    background: linear-gradient(#33333399, #77777799);
    box-shadow: 0px 0px 50px #777 inset;
  }

  100% {
    opacity: 1;
    background: linear-gradient(#33333399, #77777799);
  }
}

.dayData {
  opacity: 0.6;
  padding: 10px;
  position: absolute;
  font-size: 14px;
  padding: 10px;
  top: 55px;
  height: 150px;
  width: 500px;
}

.dayData p {
  margin-top: 10px;
  font-size: 12px;
  width: 250px;
  text-align: left;
  line-height: 10px;
}

@-webkit-keyframes softAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes softAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* [contentEditable] {
  outline: none;
} */
[contentEditable]:after {
  opacity: 0.3;
  font-size: 12px;
  margin-left: 10px;
  content: "";
}
[contentEditable]:focus {
  outline: none;
  /* background: #00000044; */
  display: inline-block;
  color: #00ffd4;
}

[contentEditable]:hover {
  cursor: pointer;
  /* color: orange; */
  opacity: 0.8;
  display: inline-block;
}

.bookingFormTable {
  font-size: 14px;
  line-height: 12px;
}

.bookingFormTable td {
  padding: 5px;
}

.bookingFormTable [contentEditable] {
  /* display: inline-block; */
  /* background: #00000011; */
}

.bookingFormTable td:nth-child(odd) {
  color: #888;
}
.bookingFormTable td:nth-child(even) {
}

.gridSample {
  /* background: blue; */
  opacity: "0.5";
  height: 1vh;
  /* margin: 10px; */
  /* border: 1px solid #00ffd4; */
  /* overflow: scroll; */
  padding: 0 !important;
}

/* .pageContainer {
  position: relative;
  scroll-snap-type: y mandatory;
  width: 100% !important;
  height: 100vh !important;
  overflow-y: scroll;
}

.fullPage {
  scroll-snap-align: start;
  height: 100vh;
} */

.frameA {
  /* background: rgba(0, 0, 255, 0.277); */
  /* height: 70vh; */
  height: auto;
}

.frameB {
  /* background: rgba(255, 192, 203, 0.401); */
  position: relative;
  scroll-snap-type: y mandatory;
  height: 70vh;
  overflow: auto;
}

/* If screenwidth is less than 1281... */
@media (max-width: 1281px) {
  .frameB {
    /* height: 100vh; */
    overflow: scroll !important;
  }
}

/* If screenwidth is less than 1410... */
@media (max-width: 1410px) {
  .frameLeft {
    /* background: rgba(255, 0, 0, 0.16); */
    min-width: 100% !important;
  }
  .frameRight {
    /* background: rgba(0, 38, 255, 0.16); */
    min-width: 100% !important;
  }
}

.frameLeft {
  /* background: rgba(255, 0, 0, 0.16); */
  /* scroll-snap-align: start; */
  height: 50vh;
  display: flex;
  min-width: 640px;
  min-height: 700px;
  padding: 0px 20px;
  overflow: scroll;
}

.frameRight {
  /* background: rgba(255, 240, 0, 0.16); */
  /* scroll-snap-align: start; */
  /* height: 70vh; */
  height: 70vh;
  min-width: 640px;
  padding: 20px;
  grid-gap: 15px;
  gap: 15px;
  display: grid;
}

.frameRight .TabPanels {
  /* background: rgba(255, 240, 0, 0.15); */
  /* position: absolute; */
  margin-top: 100px;
  overflow: auto;
  height: auto;
  /* min-height: 70vh; */
  padding: 0 !important;
  width: 100% !important;
  margin: 0 !important;
  display: flex !important;
}

.customerInfo {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-content: baseline;
}

.fixedButtonBarBottom {
  display: flex;
  height: 40px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  grid-gap: 10px;
  gap: 10px;
}

input.bookingArtist {
  color: white;
  background: transparent;
  box-shadow: none;
  border: none;
  font-size: 42px;
  font-weight: 900;
}

.productFeatures .vatLabel {
  display: none;
}

.productsWithFeatures .MuiToggleButtonGroup-root {
  padding: 0;
}

.standardTimeSlider {
}

.css-1fhu4ej-MuiSlider-root {
  height: 1px !important;
  color: hsl(170, 56%, 15%) !important;
}

.MuiSlider-root {
  height: 1px !important;
}

.verticalSlider .MuiSlider-root {
  height: 100% !important;
}

.MuiSlider-thumb {
  /* background-color: #184c5c; */
  /* background-color: linear-gradient(0deg, #00ffd4, transparent) !important; */
  background: linear-gradient(90, #00ffd4, #113c35) !important;
  z-index: 2;
  height: 20px !important;
  width: 7px !important;
  border-radius: 5px !important;
}

.MuiSlider-thumb:nth-of-type(8) {
  /* background: linear-gradient(180deg, #00ffd4, #113c35) !important; */
  background: linear-gradient(270deg, #00ffd4, #008a73) !important;
  box-shadow: 0px 0px 5px 0px #00ffd499;
  height: 25px !important;
  z-index: 2;
  border-left: 1px solid #fff;
}
.MuiSlider-thumb:nth-of-type(12) {
  /* background: linear-gradient(180deg, #00ffd4, #113c35) !important; */
  background: linear-gradient(88deg, #00ffd4, #008a73) !important;
  box-shadow: 0px 0px 5px 0px #00ffd499;
  height: 25px !important;
  z-index: 2;
  border-right: 1px solid #fff;
}
/* .MuiSlider-thumb:nth-of-type(8) {
  background: linear-gradient(180deg, #00ffd4, #00ffd4) !important;
  box-shadow: 0px 0px 5px 0px #00ffd455;
  height: 25px !important;
  z-index: 2;
} */

.timeSlotHighlight {
  background: repeating-linear-gradient(70deg, #00ffd4, #000 4px, #00ffd4 6px, #00ffd499 1px);
  height: 8px;
  left: 30.1667%;
  right: 30.25%;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 5px 0px #00ffd455;
  top: 9.5px !important;
}

.simpleTimeField {
  width: 12%;
  min-width: 45px;
}

.simpleTimeField label {
  font-size: 10px;
  text-align: center;
  opacity: 0.7;
  width: 45px !important;
}

.simpleTimeField input {
  width: 45px !important;
  background: none !important;
  border: 0;
  color: white;
  text-align: center;
  font-size: 14px;
  opacity: 1 !important;
}

.tBodz {
  max-height: 46vh;
  overflow: auto;
}

/* .bookingFeaturesPriceCalculated td {
  width: 80px;
  text-align: center;
} */

.frameLeft tr {
  height: 60px;
}

.InfoBox {
  margin: 20px;
}

.paymentSlider {
  max-width: 500px;
  padding: 15px;
}

.paymentSlider .MuiSlider-markLabel[data-index="0"] {
  -webkit-transform: translateX(-50%) translateY(15px);
          transform: translateX(-50%) translateY(15px); /* Adjust Y offset */
  display: none;
}
.paymentSlider .MuiSlider-markLabel[data-index="1"] {
  -webkit-transform: translateX(-50%) translateY(15px);
          transform: translateX(-50%) translateY(15px); /* Adjust Y offset */
  display: none;
}
.paymentSlider .MuiSlider-markLabel[data-index="2"] {
  -webkit-transform: translateX(-50%) translateY(5px);
          transform: translateX(-50%) translateY(5px); /* Adjust Y offset */
  display: none;
}
.paymentSlider .MuiSlider-markLabel[data-index="3"] {
  -webkit-transform: translateX(-50%) translateY(15px);
          transform: translateX(-50%) translateY(15px); /* Adjust Y offset */
  display: none;
}
.paymentSlider .MuiSlider-markLabel[data-index="4"] {
  -webkit-transform: translateX(-50%) translateY(5px);
          transform: translateX(-50%) translateY(5px); /* Adjust Y offset */
}
.paymentSlider .MuiSlider-thumb {
  z-index: 2;
  height: 20px !important;
  width: 7px !important;
  border-radius: 5px !important;
}
.paymentSlider .MuiSlider-root {
  height: 1px !important;
  /* color: orange !important; */
}
/* .paymentSlider .MuiSlider-track {
  background: $secondary !important;
  height: 5px !important;
  border-radius: 5px !important;
} */

.stepsSlider {
  width: 80px !important;
  background: #00ffd411;
  padding: 0px 17px;
  height: 38px;
  border-radius: 10px;
}

.stepsSlider .MuiSlider-track {
  display: none !important;
}

.stepsSlider .MuiSlider-rail {
  display: none !important;
}

.stepsSlider .MuiSlider-thumb {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 0;
  background-color: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.formDescription {
  text-transform: none;
  color: #999;
  font-weight: 100;
  font-style: italic;
}

.paymentGraphSlider {
  width: 100%;
  height: 180px;
  max-width: 700px;
  margin: 0;
}

.paymentGraph {
  display: flex;
  width: calc(27.3% - 15px);
  height: 130px;
}

.paymentGraph label {
  display: flex;
  /* z-index: 50000; */
  position: absolute;
  margin-left: -30px;
  margin-top: -20px;
  grid-gap: 5px;
  gap: 5px;
}

.paymentGraph .MuiSlider-thumb {
  height: 2px !important;
  width: 30px !important;
  left: 15px;
  -webkit-transform: translate(-25px, -1px);
          transform: translate(-25px, -1px);
}

.paymentGraph .MuiSlider-rail {
  width: 20px;
  /* bottom: -9.5px; */
  opacity: 0.2;
  left: 0px !important;
  border-radius: 0px 0px 0px 0px;
}

.paymentGraph .upfront .MuiSlider-track {
  background: repeating-linear-gradient(0deg, #00ffd422, #00ffd466 3px, #00ffd422 3px, #00ffd499 3px);
  position: absolute;
  /* bottom: -9.5px !important; */
  left: 0px;
  border: 0;
  width: 20px;
  border-radius: 0px 0px 0px 0px;
}

.paymentGraph .deposit .MuiSlider-track {
  background: repeating-linear-gradient(45deg, #00ffd422, #00ffd466 4px, #00ffd422 4px, #00ffd499 4px);
  position: absolute;
  /* bottom: -9.5px !important; */
  left: 0px;
  border: 0;
  width: 20px;
  border-radius: 0px 0px 0px 0px;
}

.paymentGraph .balance .MuiSlider-track {
  background: repeating-linear-gradient(135deg, #00ffd422, #00ffd466 5px, #00ffd422 5px, #00ffd499 5px);
  position: absolute;
  /* bottom: -9.5px !important; */
  left: 0px;
  border: 0;
  width: 20px;
  border-radius: 0px 0px 0px 0px;
}

.paymentGraph .balance label {
  display: flex;
  position: absolute;

  /* margin-left: -84px; */
  /* margin-top: -20px; */
}

.paymentGraph label {
  padding: 0px 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0px 0px 10px 0px #00ffd455;
}

.cancelationGraphs label {
  padding: 0px 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: 0px 0px 10px 0px #00ffd455;
}

.paymentGraph .deposit:hover,
.paymentGraph .upfront:hover {
  z-index: 2;
}

.paymentGraph .MuiSlider-root {
  height: 100px !important;
  /* top: 10px; */
  top: 10px !important;
}

.cancelationSlider {
  height: 300px;
  width: 100% !important;
  max-width: 500px;
  min-width: 300px;
  margin-top: 50px;
}

.cancelationSlider .MuiSlider-thumb {
  height: 20px !important;
  width: 8px !important;
  background: #00ffd4;
  /* left: 10px; */
}

/* .cancelationSlider .MuiSlider-track {
  display: none;
} */

.cancelationGraphs .MuiSlider-root {
  height: 100px !important;
  top: 10px;
}

.cancelationGraphs .MuiSlider-rail {
  left: 0px;
  width: 20px;
  border-radius: 0px 0px 0px 0px;
  bottom: 2px;
  opacity: 0.2;
}

.cancelationGraphs .MuiSlider-track {
  background: repeating-linear-gradient(135deg, #00ffd422, #00ffd466 5px, #00ffd422 5px, #00ffd499 5px) !important;
  width: 20px !important;
  border-radius: 0;
  left: 0px;
  border: none;
  bottom: 2px !important;
}

.cancelationGraphs .MuiSlider-thumb {
  height: 2px !important;
  width: 30px !important;
  left: 15px;
  -webkit-transform: translate(-25px, -1px);
          transform: translate(-25px, -1px);
}

infoIcon {
  font-weight: 900;
  padding: 4px 10px;
  background: #777;
  color: white;
  height: 20px;
  border-radius: 50%;
  width: 20px;
}

.bookingMenu {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  /* backdrop-filter: blur(10px); */
  -webkit-backdrop-filter: opacity(0);
          backdrop-filter: opacity(0);
  z-index: 2;
  height: 48px;
}

.categoryHeader {
  border-bottom: 1px solid #00000022;
  text-transform: uppercase;
  opacity: 0.5;
  border-top: 5px solid transparent;
}

.ticketInfo {
  pointer-events: none;
  position: absolute;
  top: 100px;
}

.onHover {
  display: none;
}

.onHover:hover {
  display: block;
}

.staffInvite p {
  pointer-events: none;
}

.staffInvite {
  opacity: 1;
  background: orange;
  color: black;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  width: 60px;
  height: 20px;
  display: table-cell;
}

.staffInvite:hover {
  opacity: 0.5;
}

.staffTable tr {
  /* border: 1px solid #00ffde99; */
  width: 100%;
  table-layout: fixed;
}

.staffTable td input {
  /* max-width: 100%; */
  width: 100%;
  color: white;
  background: transparent;
  border: none;
}

.staffTable td input:hover {
  color: black;
  background: white;
  border: none;
  border-radius: 5px;
}

.standardDialog {
  grid-gap: 15px;
  gap: 15px;
  width: 30vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editOn {
}

.editOff {
  display: none;
}

.publicEvent {
  background: #00ffd411;
  border-radius: 10px;
  padding: 0px 5px;
  color: #00ffd4;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}

.privateEvent {
  background: #00ffd411;
  border-radius: 10px;
  padding: 0px 5px;
  color: #00ffd4;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  before: "Private Event content";
}

.adminTools {
  background: #25507599;
}

table.devTodoList button {
  border-radius: 5px;
  box-shadow: none;
  border: none;
  background: #00ffd499;
  font-size: 12px;
  border: 1px solid black;
}

table.devTodoList td,
table.devTodoList tr {
  border: 1px solid #00ffd455;
  padding: 5px;
}

.connectAgreement .MuiToggleButton-root {
  width: 150px;
  height: 150px;
  margin: 10px;
  border: none !important;
}

.connectAgreement .Mui-selected {
  overflow: hidden;
  width: 150px;
  background: linear-gradient(0deg, #00ffd411, #00ffd499) !important;
  color: #222 !important;
  margin: 10px;
  color: white !important;
  border: 2px solid #00ffd4 !important;
}

.connectAgreement .image img {
  width: 150px !important;
  position: absolute;
  top: 15px;
  left: 0px;
  height: auto;
}

.connectAgreement .title {
  position: absolute;
  top: 110px;
  text-transform: initial;
  font-weight: 900;
  width: 90%;
  line-height: 15px;
}

.connectAgreement .date {
  width: 100%;
  position: absolute;
  top: 0px;
  background: #000;
}

.connectedBookings {
  width: 100px;
  font-size: 11px;
  padding: 5px;
  text-align: center;
  border: 1px solid #00ffd499;
  border-radius: 10px;
  height: 140px;
  margin: 5px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-falsePrimary.MuiButton-sizeSmall.MuiButton-falseSizeSmall.MuiButton-root.MuiButton-falsePrimary.MuiButton-sizeSmall.MuiButton-falseSizeSmall.css-a3xx3e-MuiButtonBase-root-MuiButton-root {
  text-transform: initial;
  font-weight: 900;
}

.tidyForm {
  display: table;
  width: 100%;
}

.evp-artist-image {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000000;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 1px 15px 5px #000000;
}

.evp-artist-image img {
  width: 100%;
  height: 100%;
}

.evp-artist-info h2 {
  position: absolute;
  top: 191px;
  text-shadow: 2px 2px 6px black;
  left: 30px;
  z-index: 1000000;
  font-size: 16px !important;
  text-transform: uppercase;
}

.evp-artist-image-blur {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000000;
  width: 600px;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-filter: blur(100px);
          filter: blur(100px);
  opacity: 0.2;
}

.evp-QR-image {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.evp-venue-logo {
  width: 300px;
  height: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  overflow: hidden;
}

.evp-venue-logo img {
  width: 300px;
  height: auto;
}

.evp-msg {
  position: absolute;
  z-index: 1;
  font-size: 55px;
  font-weight: 900;
  text-align: center;
  top: 30%;
  width: 100vw;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  opacity: 1;
  -webkit-animation: evpmessage 4s infinite;
          animation: evpmessage 4s infinite;
}
@-webkit-keyframes evpmessage {
  from {
    color: #00ffd4;
  }

  to {
    color: orange;
  }
}
@keyframes evpmessage {
  from {
    color: #00ffd4;
  }

  to {
    color: orange;
  }
}

.handleUsers-user {
  background: #44444433;
  padding: 10px;
  border-radius: 10px;
}

.vatLabel {
  line-height: 12px;
}

.MuiFormControl-root .MuiFormControl-fullWidth .MuiTextField-root .internal .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background: "#00ffd499" !important;
}

.moduleNotes {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 5px;
}
.moduleInfo {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin: 5px;
}

.moduleEconomy {
  margin: 5px;
  padding: 10px;
  background: #00000033;
  border-radius: 10px;
  /* border: 1px solid #00ffd433; */
  box-shadow: 3px 1px 3px 0px inset #000000;
}

.optionName {
  width: 100%;
  font-weight: 900;
  background: none;
  box-shadow: none;
  border: none;
  color: inherit;
  font-size: 20px;
  text-indent: 10px;
}

.optionName:hover {
  background: #00000033;
  box-shadow: 3px 1px 3px 0px inset #000000;
  border-radius: 5px;
}

.optionName::-webkit-input-placeholder {
  color: #00ffd4;

  -webkit-animation: enterName 3s;

          animation: enterName 3s;
}

.optionName::placeholder {
  color: #00ffd4;

  -webkit-animation: enterName 3s;

          animation: enterName 3s;
}

@-webkit-keyframes enterName {
  0% {
    color: #00ffd4;
    opacity: 0.3;
  }
  50% {
    color: #ff8400;
  }
  100% {
    color: #ff009d;
  }
}

@keyframes enterName {
  0% {
    color: #00ffd4;
    opacity: 0.3;
  }
  50% {
    color: #ff8400;
  }
  100% {
    color: #ff009d;
  }
}

.moduleTable {
  border-collapse: collapse;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.moduleTable tr {
  background: #00ffd411;
  flex: 1 0 auto;
}

.css-1310l4m-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  background: transparent;
}

.moduleOption {
  margin-bottom: 30px;
}

.refreshButton {
  color: #00ffd4 !important;
  zoom: 0.8;
}

.refreshButton:hover {
  color: orange !important;
}

.refreshButton:active {
  -webkit-animation: rotate 1s;
          animation: rotate 1s;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.text {
  white-space: pre-wrap;
}

.refreshAll {
  margin-top: -26px !important;
}

.specialConditions {
  margin-bottom: 15px;
}

.agreementBox {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: 80vh;
  background-color: #121212;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  overflow: scroll;
}

#agreementWrapper {
  width: 800px;
}

/* .venueCalendarWrapper {
  padding: 10px 20px 0 20px;
}

.ownerHomeMiddle {
}

.BookingsByMonth {
  margin: 0px !important;
}

.BookingsByMonthContainer {
  gap: 1rem;
} */

.productBuilderTabContext {
  height: 60vh;
}

.productBuilderTabs {
  border-bottom: 1px solid #333;
}

/* ANGELO */

@media (max-width: 600px) {
  .upcomingEventContainer > div > div > div > div:not(:has(> div)) {
    margin-left: 0px;
  }

  .upcomingEventContainer > div > div:first-of-type {
    flex-direction: column;
    flex-flow: none;
  }

  .upcomingEventContainer > div > div > div:nth-of-type(2) {
    flex-flow: none;
    max-width: none;
    justify-content: space-around;
  }

  :has(> .statsContainer) {
    padding: 8px;
  }
}

/* ANGELO SLUT */
/* ANGELO */

::-webkit-scrollbar {
  background: inherit;
}
::-webkit-scrollbar-corner {
  background: inherit;
}

/* ANGELO SLUT */
/* ANGELO */

/* denna löser undersidan avails som endast dyker fram under 900px */
@media (max-width: 900) {
}
#outlet {
  padding-right: 0px;
  padding: 40px;
}

/* denna löser popupen */
@media (max-width: 640px) {
  .frameA {
    height: auto !important;
    width: 110% !important;
    max-width: none !important;
  }
}

/* ANGELO SLUT */
/* ANGELO */

/* detta löser summary knapparna i mobilformat*/

@media (max-width: 700px) {
  .fixedButtonBarBottom {
    display: grid !important;

    grid-template-columns: repeat(2, 1fr) !important;

    height: 10% !important;
  }
}

/*detta löser agreement*/

.signatures ~ div:nth-of-type(2) {
  padding: 1.5rem;
}

.cancelationsSlider {
  padding-left: 1rem;
}

/*detta löser bordern på log in*/

.fieldset {
  border-radius: 0px !important;
}

/*detta löser landing page full width*/

.pageContainer {
  overflow-y: unset;
}

/* ANGELO SLUT */

.bottomLoading {
  position: fixed;
  width: 100vw;
  /* background: red; */
  bottom: 0px;
}

.versionNumber {
  position: fixed;
  bottom: 10px;
}

.onlineIndicator {
  opacity: 1;
  position: fixed;
  bottom: 10px;
}

.reportArtist {
  /* background: #111;
  position: sticky;
  top: 20vh;
  z-index: 2; */
}

.reportGraphs {
  /* background: black; */
  /* position: sticky;
  top: 30vh;
  z-index: 2; */
}

.reportData {
  /* background: black; */
  /* position: sticky;
  top: 50vh;
  z-index: 2; */
}

.reportData .sustainabilityReport {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 1px solid #444;
  padding: 5px;
  color: #666;
  width: 100%;
  font-size: 17px;
}

.cleanTable {
  background: #00ffd422;
}

.calendarOverviewContainer {
}

.calendarOverview {
}

.overviewSwitch {
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 20px;
  position: fixed;
  /* z-index: 10000; */
  bottom: 10px;
}

.multiBookingsCalendar {
  background: linear-gradient(180deg, #252525, #151515);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 1000 !important;
  width: 100%;
  border-radius: 20px;
}

.overViewGrid {
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  max-width: 70vw !important;
  /* overflow: scroll; */
}

.bookingRow:hover {
  /* background: linear-gradient(#00ffd422, rgba(255, 166, 0, 0.169)); */
  background: #00ffd411;
}

.bookingCell {
  padding: 2px;
  border-left: 1px dashed #ffb7b536;
}

td.bookingCell,
th.bookingCell {
  border-top: 5px inset transparent; /* Inset border for each cell */
  border-bottom: 5px inset transparent; /* Inset border for each cell */
  /*padding: 8px; */
}

.mbcFixedCell {
  /* position: absolute; */
}

.miniBookingContainer {
  width: 100% !important;
  border: 2px solid #ffffff22 !important;
}

.miniBookingContainer:hover {
  width: 100% !important;
  border: 2px solid #00ffd444 !important;
}

.middle {
  /* background: purple; */
  overflow: hidden;
  position: relative;
}

/** @format */

* @format button {
  width: 120px;
  height: 50px;
  color: white;
  background-color: black;
  border: none;
}
.BB {
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(0, 0, 0);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  padding: 1rem;
  overflow: hidden;
  width: 32%;
  scale: 1;
  transition: width 2s ease;
}
.BBS {
  font-family: "Times New Roman", Times, serif;
  background-color: rgb(0, 0, 0);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: white;
  padding: 1rem;
  overflow: hidden;
  width: 86%;
  scale: 1;
  transition: width 2s ease;
}
.tog {
  display: none;
}
.vis {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.MB {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
}
.SM {
  height: 100%;
  width: 33.3%;
  margin-right: 20px;
  scroll-snap-align: start;
}

.SMB {
  height: 100%;
  width: 100%;
}
.dayB {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  text-align: center;
}
.DayBox {
  position: relative;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.DayBox.confirmed {
  background-color: lightgreen;
  color: lightgreen;
}

.DayBox.pending {
  background-color: yellow;
  color: yellow;
}

.PendingPopup {
  position: absolute;
  top: 0;
  left: 20px;
  display: none;
  background-color: rgba(0, 180, 81, 0.601);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  padding: 10px;
  z-index: 1000;
  width: 50px;
  font-size: 0.5rem;
  border-radius: 8px;
}

.DayBox.pending:hover .PendingPopup {
  display: block;
}
.PendingPop {
  position: absolute;
  top: 0;
  left: 0px;
  background-color: rgba(0, 180, 81, 0.601);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  z-index: 1000;
  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  color: black;
}
.PendingPop p {
  margin: none;
  font-size: 0.7rem;
}

.DayBox.pending:hover .PendingPop {
  display: block;
}

.DayBox.killed {
  background-color: salmon;
  color: salmon;
}

.DayBox.artist {
  background-color: lightblue;
  color: lightblue;
}
.EmptyDay {
  border-radius: 4px;
}
.GridCal div:not(.EmptyDay):hover {
  border: solid white 1px;
  cursor: pointer;
  padding: 1px;
  color: rgb(0, 0, 0) !important;
}
.dayB p {
  font-size: 0.5rem;
}
.Bday {
  margin: 0;
  text-align: center;
  border-right: solid 1px white;
}
.GridCal {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
}
.GridCal div {
  text-align: center;
  font-size: 0.7rem;
  height: auto;
  width: auto;
  color: black;
}
.Ledigt {
  background-color: rgb(255, 255, 255);
  width: 15px;
  height: 15px;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: black;
}
.Ledigt:hover {
  cursor: pointer;
}

@media (max-width: 900px) {
  .SM {
    width: 50%;
  }
  .dayB {
    grid-gap: 7px;
    gap: 7px;
  }
}
@media (max-width: 700px) {
  .SM {
    width: 50%;
  }
  .GridCal div {
    font-size: 0.5rem;
  }
  .dayB p {
    font-size: 0.5rem;
  }
  .dayB {
    grid-gap: 7px;
    gap: 7px;
  }
  .Bday {
    font-size: 1rem;
  }
}
@media (max-width: 540px) {
  .SM {
    width: auto;
  }
  .GridCal div {
    font-size: 0.4rem;
  }
  .dayB p {
    font-size: 0.5rem;
  }
  .dayB {
    grid-gap: 7px;
    gap: 7px;
  }
  .Bday {
    font-size: 0.6rem;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: #184c5c;

  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation__label {
  color: #e6e6e6
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  color: #e6e6e6
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  color: #e6e6e6
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  color: #e6e6e6
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  color: #e6e6e6
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #fff;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #535353;
}

.react-calendar {
  background-color: #184c5c;
  color: rgb(244, 244, 244);

}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  color: #3b3b3b;
  background-color: #141414;
}

.react-calendar__month-view__days__day {
  color: rgb(246, 246, 246);
  background-color: #141414;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #487889;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #487889;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #487889;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #487889;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
